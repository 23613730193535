import React from 'react';
import './list.scss';

interface Props extends React.PropsWithChildren {
    head: React.ReactNode;
}

const List: React.FC<Props> = ({ children, head }) => {
    return (
        <div id="list">
            <table>
                <thead>{head}</thead>
                <tbody>{children}</tbody>
            </table>
        </div>
    );
};

export default List;
