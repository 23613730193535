import {
    ApolloClient,
    NormalizedCacheObject,
    ObservableQuery,
    OperationVariables,
} from '@apollo/client';
import { GETEQUIPMENTS } from '_queries';
import { ObjPosition } from '_types';
import { apolloClient } from 'app/apolloClient';
import { Visualization } from 'components/Visualizer/visualization';
import { Subscription } from '@apollo/client/node_modules/zen-observable-ts/module';
import { LDM } from '../ldm';

export class LiveDensityData implements LDM {
    viz: Visualization;
    client: ApolloClient<NormalizedCacheObject>;
    dataIsDirty: boolean;
    query: ObservableQuery<any, OperationVariables>;
    peoplePosition: ObjPosition[];
    assetSubscription: Subscription;

    constructor() {
        this.client = apolloClient;
        this.SetQuery(0);
    }

    private SetQuery(pollInterval: number) {
        this.query = this.client.watchQuery({
            query: GETEQUIPMENTS,
            pollInterval,
        });
    }

    public SetMainViewSubscription(viz: Visualization, pollInterval: number) {
        if (viz) this.viz = viz;

        this.SetQuery(pollInterval);
        const subscription = this.query.subscribe({
            next: (data) => this.subscribeFunction.bind(this)(),
            error: (e) => this.subscribeFunction.bind(this)(),
        });

        this.assetSubscription = subscription;
    }

    private subscribeFunction() {
        this.viz.layers.density.BuildArea(
            [188.931, 196.296],
            -20.753976821899414,
            [-151.25, -146.25],
            'a',
            'D'
        );
    }

    public StopMainViewSubscription() {
        if (!this.assetSubscription) return;

        this.viz.layers.density.RemoveAreas();
        this.assetSubscription.unsubscribe();
    }
}
