import React from 'react';
import './simulationStationList.scss';
import '../simulations.scss';
import List from 'components/List/List';
import { stations } from 'features/stationFeatures/stationFeatures';
import { Link, useLocation } from 'wouter';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { selectAccessToken } from 'features/auth/AuthSlice';
import { Button } from 'components/UIComponents/Button/Button';
import { ReactComponent as ArrowRight } from 'assets/icons/left-icon.svg';
import { SimulationResponse } from '_types';
import _ from 'lodash';
import { simulationAPI, useGetDigiTwinUIAPIQuery } from 'app/store';

type SimulationList = { [stationName: string]: number };

const SimulationStationList: React.FC = () => {
    const [location, setLocation] = useLocation();
    const dispatch = useAppDispatch();

    const [simulationsList, setSimulationsList] =
        React.useState<SimulationList | undefined>(undefined);

    /* const { data, error } = useGetDigiTwinUIAPIQuery('/rautatientori/cameraviews');

    // React.useEffect(() => console.log(JSON.stringify(data, null, 2)), [data]);
    React.useEffect(() => console.log(data), [data]);
    React.useEffect(() => console.log(error), [error]); */

    React.useEffect(() => {
        stations.forEach((stationName) => {
            const promise = dispatch(
                simulationAPI.endpoints.getSimulationAPI.initiate(`/${stationName.toLowerCase()}`)
            );
            promise.then((res) =>
                setSimulationsList((s) => ({ ...s, [stationName]: res.data?.length || 0 }))
            );
        });
    }, []);

    return (
        <div id="simulation-main-view">
            <main>
                <div className="page-title">Stations overview</div>
                <List
                    head={
                        <tr>
                            <td>Station name</td>
                            <td>Simulations</td>
                            <td>Details</td>
                        </tr>
                    }>
                    {stations.map((station) => (
                        <tr key={station}>
                            <td>
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        setLocation(
                                            station === 'Rautatientori'
                                                ? 'simulations/rautatientori'
                                                : '#'
                                        )
                                    }>
                                    {station}
                                </div>
                            </td>
                            <td>{simulationsList?.[station]}</td>
                            <td>
                                <div
                                    style={{ cursor: 'pointer' }}
                                    onClick={() =>
                                        setLocation(
                                            station === 'Rautatientori'
                                                ? 'simulations/rautatientori'
                                                : '#'
                                        )
                                    }>
                                    <Button
                                        className={`secondary ${
                                            station !== 'Rautatientori' && 'disabled'
                                        }`}
                                        style={{ paddingTop: '4px', paddingBottom: '4px' }}>
                                        View more <ArrowRight fill="currentColor" />
                                    </Button>
                                </div>
                            </td>
                        </tr>
                    ))}
                </List>
            </main>
        </div>
    );
};

export default SimulationStationList;
