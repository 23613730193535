import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CameraView, AgentDataPoint, Views, SimulationStateType } from '_types';
import { VizStore } from 'components/Visualizer/Visualizer';

import { RootState } from '../../app/store';

export type MainViewOptions = 'live' | 'dashboard' | 'playback' | 'analysis' | 'virtual operator';

export interface StationViewState {
    mainSidebarFull: boolean;
    mainView: MainViewOptions;

    briefingAlerts: boolean;

    cameraViews: Views;
    currentCameraView: CameraView;
    payloadHistory: number[];
    status: 'idle' | 'pending' | 'failed';
}

const initialState: StationViewState = {
    mainSidebarFull: true,
    mainView: 'live',

    briefingAlerts: false,

    cameraViews: new Map<
        string,
        { label: string; camera: { eye: THREE.Vector3; target: THREE.Vector3 } }
    >(),
    currentCameraView: { label: 'Entrance', key: 'entrance' },
    status: 'idle',
    payloadHistory: [],
};

/*===============================
             Thunks
===============================*/

export const setCurrentCameraView = createAsyncThunk(
    'StationView/setCurrentCameraView',
    async (view: CameraView) => {
        VizStore.setView(view.key);
        return view;
    }
);

/*===============================
              Slice
===============================*/

export const StationViewSlice = createSlice({
    name: 'StationView',
    initialState,
    reducers: {
        setMainSidebarFull: (state, action: PayloadAction<boolean>) => {
            state.mainSidebarFull = action.payload;
        },
        setMainView: (state, action: PayloadAction<MainViewOptions>) => {
            state.mainView = action.payload;
            localStorage.setItem('mainView', action.payload);
        },

        setBriefingAlerts: (state, action: PayloadAction<boolean>) => {
            state.briefingAlerts = action.payload;
        },

        setPayloadHistory: (state, action: PayloadAction<number[]>) => {
            state.payloadHistory = action.payload;
        },
        setCameraViews: (state, action: PayloadAction<Views>) => {
            state.cameraViews = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setCurrentCameraView.pending, (state) => {
                state.status = 'pending';
            })
            .addCase(setCurrentCameraView.fulfilled, (state, action) => {
                state.status = 'idle';
                state.currentCameraView = action.payload;
            })
            .addCase(setCurrentCameraView.rejected, (state) => {
                state.status = 'failed';
            });
    },
});

/*===============================
            Actions
===============================*/

export const {
    setMainSidebarFull,
    setMainView,

    setBriefingAlerts,

    setPayloadHistory,
    setCameraViews,
} = StationViewSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectMainSidebarFull = (state: RootState) => state.stationView.mainSidebarFull;
export const selectMainView = (state: RootState) => state.stationView.mainView;

export const selectBriefingAlerts = (state: RootState) => state.stationView.briefingAlerts;

export const selectStationView = (state: RootState) => state.stationView;
export const selectCurrentCameraView = (state: RootState) => state.stationView.currentCameraView;
export const selectCameraViews = (state: RootState) => state.stationView.cameraViews;

export default StationViewSlice.reducer;
