import { StationParams } from '_types';
import { Viewport } from '../viewport';
import * as THREE from 'three';
import { densityAreaColorMap } from 'features/DataManager/util';
import { setYAxisVisibility } from '../util';

type LOS = 'A' | 'B' | 'C' | 'D' | 'E' | 'F';

export class DensityAssets {
    vpt: Viewport;
    scene: THREE.Group;
    params?: StationParams;
    areas: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshBasicMaterial>[];
    axisYLimits: { bottom: number; top: number };

    constructor(vpt: Viewport) {
        this.vpt = vpt;

        this.scene = new THREE.Group();
        this.scene.name = 'DensityAssets';

        this.areas = [];
        this.axisYLimits = { bottom: -Infinity, top: Infinity };

        this.vpt.scene.add(this.scene);
    }

    public BuildArea(x: number[], y: number, z: number[], name: string, los: LOS) {
        if (x.length !== 2 || z.length !== 2) {
            console.error(
                'Density area should be defined with upper and lower limits on x and z axis and une value on y axis'
            );
            return;
        }

        if (this.areas.map((obj) => obj.name).includes(name)) return;

        const width = x.sort((a, b) => a - b).reduce((a, b) => a - b);
        const heigth = z.sort((a, b) => a - b).reduce((a, b) => a - b);

        const geometry = new THREE.PlaneGeometry(width, heigth);
        const material = new THREE.MeshBasicMaterial({
            color: densityAreaColorMap[los],
            side: THREE.DoubleSide,
            opacity: 0.5,
            transparent: true,
        });
        const plane = new THREE.Mesh(geometry, material);
        plane.name = name;

        this.areas.push(plane);

        plane.rotateX(Math.PI / 2);

        const x1 = (x[1] + x[0]) / 2;
        const y1 = y;
        const z1 = (z[1] + z[0]) / 2;
        plane.position.set(x1, y1, z1);

        this.scene.add(plane);

        this.SetAxisYLimits(this.axisYLimits.bottom, this.axisYLimits.top);
    }

    public SetAxisYLimits(bottom?: number, top?: number) {
        this.axisYLimits = {
            bottom: bottom ?? -Infinity,
            top: top ?? Infinity,
        };

        this.areas.forEach((mesh) => {
            mesh.visible = setYAxisVisibility(
                mesh.position.y,
                this.axisYLimits.bottom,
                this.axisYLimits.top
            );
        });
    }

    public RemoveAreas() {
        if (!this.areas) return;

        this.areas.forEach((mesh) => this.scene.remove(mesh));
        this.areas = [];
    }
}
