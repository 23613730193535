import React from 'react';
import NumericInput from 'components/UIComponents/NumericInput/NumericInput';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import _ from 'lodash';

type Props = {
    state: UsersSectionState | undefined;
    setState: React.Dispatch<React.SetStateAction<UsersSectionState | undefined>>;
    schema?: any;
    isLoading?: boolean;
    active?: boolean;
};

export type UsersSectionState = Record<string, any>;

const UsersSection: React.FC<Props> = ({ state, setState, schema, isLoading, active }) => {
    if (!schema || !state || isLoading) return <LoadingPage />;

    return (
        <div className="users-section">
            <GenerateFromSchema
                schema={schema}
                setState={setState}
                state={state}
                level={0}
                path=""
                active={active}
            />
        </div>
    );
};

export default UsersSection;

interface GenerateFromSchemaProps {
    schema: Record<string, any>;
    state: Record<string, any>;
    setState: React.Dispatch<React.SetStateAction<UsersSectionState>>;
    path: string;
    level: number;
    active?: boolean;
}
const GenerateFromSchema: React.FC<GenerateFromSchemaProps> = (props) => {
    const { schema, level, state, setState, path = '', active } = props;
    const properties: Record<string, any> = schema.properties;
    let isLastParent = true;
    Object.values(properties ?? {}).forEach(
        (o) => (isLastParent = isLastParent && (o as any).type === 'number')
    );
    isLastParent = isLastParent && !!properties;

    return (
        <div className={`users-layout ${isLastParent ? 'horizontal' : 'vertical'} lv-${level}`}>
            {Object.entries(properties).map(([label, value]) => {
                if (value.type === 'object')
                    return (
                        <div key={label} className={`content lv-${level}`}>
                            <label className={`layout-label lv-${level + 1}`}>
                                {label.replace('_', ' ').toUpperCase()}
                            </label>
                            <GenerateFromSchema
                                {...props}
                                schema={value}
                                level={level + 1}
                                path={path + '/' + label}
                            />
                        </div>
                    );
                else if (value.type === 'number') {
                    const splitPath = (path + '/' + label).split('/').slice(1);
                    return (
                        <NumericInput
                            key={label}
                            value={splitPath.reduce((o, k) => o[k], state) as unknown as number}
                            setValue={(n) =>
                                setState((s) => {
                                    const cloneState = _.cloneDeep(s);
                                    _.set(cloneState, splitPath, n);
                                    return cloneState;
                                })
                            }
                            label={label.replace('_', ' ') + (value.maximum === 100 ? ' (%)' : '')}
                            active={active}
                        />
                    );
                }
            })}
        </div>
    );
};
