import KoneBrandMark from 'components/KoneBrandMark';
import React from 'react';
import './topNavbar.scss';
import { useSelector } from 'react-redux';
import { logoutThunk, selectUserName } from 'features/auth/AuthSlice';
import { ReactComponent as UserIcon } from 'assets/icons/user-icon.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import { RiLogoutBoxLine } from 'react-icons/ri';
import { useAppDispatch } from 'app/hooks';
import { MenuItem, ControlledMenu, useClick } from '@szhsin/react-menu';

export const TopNavbar: React.FC = () => {
    const dispatch = useAppDispatch();
    const user = useSelector(selectUserName);
    const userContainerRef = React.useRef<HTMLDivElement | null>(null);
    const [isMenuOpen, setIsMenuOpen] = React.useState<boolean>(false);
    const anchorProps = useClick(isMenuOpen, setIsMenuOpen);

    const logout = () => {
        dispatch(logoutThunk());
    };

    return (
        <nav id="top-navbar">
            <div>
                <KoneBrandMark />
                Station Twin
            </div>
            <div className="username-container" ref={userContainerRef} {...anchorProps}>
                <div className="user-icon">
                    <UserIcon fill="#717A80" />
                    <div className="user-status active"></div>
                </div>
                <span>{user}</span>
                <div className={'expand' + (isMenuOpen ? ' rotate' : '')}>
                    <ChevronDown />
                </div>
            </div>
            <ControlledMenu
                state={isMenuOpen ? 'open' : 'closed'}
                anchorRef={userContainerRef}
                onClose={() => setIsMenuOpen(false)}
                menuClassName={'menu'}
                align="end">
                <MenuItem onClick={logout} className={'menu-item'}>
                    <div
                        style={{
                            width: userContainerRef.current?.offsetWidth
                                ? `${userContainerRef.current?.offsetWidth}px`
                                : '140px',
                        }}
                        className="menu-item-content">
                        <span className="icon">
                            <RiLogoutBoxLine />
                        </span>
                        <span>Log out</span>
                    </div>
                </MenuItem>
            </ControlledMenu>
        </nav>
    );
};
