import React from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { MainViewNavBar } from 'components/MainViewNavBar/MainViewNavBar';
import { Pills } from 'components/UIComponents/Pill/Pill';
import SimulationDigiTwinView from './SimulationDigiTwin/SimulationDigiTwinView';
import ParametersView from './Parameters/ParametersView';
import { useRoute } from 'wouter';
import { SimulationDataManager } from 'features/DataManager/simulationDataManager';
import _ from 'lodash';
import { selectAccessToken } from 'features/auth/AuthSlice';
import SimulationDashBoard from './SimulationDashboard/SimulationDashBoard';
import { GETSIMULATIONJOURNEYTIMES } from '_queries';
import { useQuery } from '@apollo/client';
import { SimulationJourneyTimeQueryTipe } from '_types/queries';
import {
    setSimulationReached,
    setSimulationMainView,
    selectSimulationMainView,
    SimulationMainViewOptions,
} from 'features/StationView/SimulationSlice';

export interface InputDataType {
    users: Record<string, any>;
    equipment: {
        elevators: {
            [elevatorName: string]: boolean;
        };
        escalators: {
            [escalatorName: string]: 'up' | 'down' | 'disabled';
        };
    };
    schedules?: {
        [direction: string]: { start: number; time_interval: number };
    };
    time: {
        start: number;
        end: number;
    };
}
export interface MetaDatatype {
    name: string;
    dayOfWeek: string;
}

const SimulationMainView: React.FC = () => {
    // VIEW SELECTOR
    const simulationMainView = useAppSelector(selectSimulationMainView);
    const dispatch = useAppDispatch();
    const setView = (view: string) => {
        dispatch(setSimulationMainView(view as SimulationMainViewOptions));
    };

    // GENERAL SIMULATION INFO
    const [match, params] = useRoute('/simulations/:stationName/simulator/:simulationId');
    const stationName = params?.stationName ?? '';
    const [simulationID, startTime, endTime] = (params?.simulationId ?? '').split('_');
    const [startMs, endMs] = [startTime, endTime].map((s) => +s * 3600000);

    // RESULTS DATA
    const queryResult = useQuery<SimulationJourneyTimeQueryTipe>(GETSIMULATIONJOURNEYTIMES, {
        variables: {
            startMs,
            endMs,
            simulationID,
            timeOfDay: `${[+startTime, +endTime]}`,
            dayOfWeek: 'monday',
        },
    });

    // SIMULATIONDATAMANAGER (FOR 3D VIEW)
    const [simulationDataManager, setSimulationDataManager] =
        React.useState<SimulationDataManager>();

    React.useEffect(() => {
        const localStorageSimulationMainView = localStorage.getItem('simulationMainView');
        if (localStorageSimulationMainView) setView(localStorageSimulationMainView);
        else setView('playback');

        dispatch(setSimulationReached(startMs));

        const sdm = new SimulationDataManager(stationName);
        sdm.QueryData(simulationID, startMs, endMs);

        setSimulationDataManager(sdm);

        return () => {
            sdm.StopQuery();
            localStorage.setItem('simulationMainView', 'playback');
        };
    }, []);

    // PARAMETERS DATA
    const [inputData, setInputData] = React.useState<InputDataType>();
    const [metaData, setMetaData] = React.useState<MetaDatatype>();

    const token = useAppSelector(selectAccessToken);
    React.useEffect(() => {
        fetch(`${process.env.REACT_APP_SIMULATION_API_URL}/${stationName}/${simulationID}`, {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
        })
            .then((response) => response.json())
            .then((data) => {
                setInputData(JSON.parse(data.InputDefinition));
                setMetaData(JSON.parse(data.Metadata));
            });
    }, []);

    return (
        <div>
            <div id="stationTwin">
                <div id="stationTwin">
                    <main>
                        <MainViewNavBar>
                            <Pills
                                options={[/* 'results',  */ 'playback', 'parameters']}
                                active={simulationMainView}
                                eOnChange={setView}
                            />
                        </MainViewNavBar>
                        {simulationMainView === 'parameters' ? (
                            <ParametersView inputData={inputData} metaData={metaData} />
                        ) : simulationMainView === 'results' ? (
                            <SimulationDashBoard
                                queryResult={queryResult}
                                times={[startMs, endMs]}
                                metaData={metaData}
                            />
                        ) : (
                            <SimulationDigiTwinView
                                simulationDataManager={simulationDataManager}
                                inputData={inputData}
                            />
                        )}
                    </main>
                </div>
            </div>
        </div>
    );
};

export default SimulationMainView;
