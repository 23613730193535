import React from 'react';

import './koneBrandMark.scss';

interface KoneBrandMarkProps {
    width?: number;
    height?: number;
}

const KoneBrandMark: React.FC<KoneBrandMarkProps> = ({ width = 62, height = 32 }) => {
    return (
        <img
            id="svg"
            src={require('../../assets/KONE_Logo.png')}
            alt="KONE Logo"
            style={{ filter: 'brightness(1)' }}
            className="koneBrandMark"
        />
    );
};

export default KoneBrandMark;
