import React from 'react';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import './timePicker.scss';
import { doubleDigit } from 'components/util';

interface TimePickerProps {
    value?: number;
    onChange?: (secTimstamp: number) => void;
    showHours?: boolean;
    showSeconds?: boolean;
}

const TimePicker: React.FC<TimePickerProps> = ({
    value,
    onChange,
    showHours = true,
    showSeconds = true,
}) => {
    const [hours, setHours] = React.useState<number>(
        value ? Math.floor(value / 3600) : new Date().getHours()
    );
    const [minutes, setMinutes] = React.useState<number>(
        value ? Math.floor((value % 3600) / 60) : new Date().getMinutes()
    );
    const [seconds, setSeconds] = React.useState<number>(
        value ? Math.floor(value % 60) : new Date().getSeconds()
    );

    React.useEffect(() => {
        if (!value && value != 0) {
            setHours(new Date().getHours());
            setMinutes(new Date().getMinutes());
            setSeconds(new Date().getSeconds());
        } else {
            setHours(getHours(value));
            setMinutes(getMinutes(value));
            setSeconds(getSeconds(value));
        }
    }, [value]);

    const onChangeHours = (hours: number) => {
        if (hours > 23 || hours < 0) return;
        setHours(hours);
        if (onChange) onChange(hours * 3600 + minutes * 60 + seconds);
    };
    const onChangeMinutes = (minutes: number) => {
        if (minutes > 59 || minutes < 0) return;
        setMinutes(minutes);
        if (onChange) onChange(hours * 3600 + minutes * 60 + seconds);
    };
    const onChangeSeconds = (seconds: number) => {
        if (minutes > 59 || minutes < 0) return;
        setSeconds(seconds);
        if (onChange) onChange(hours * 3600 + minutes * 60 + seconds);
    };

    return (
        <div className="time-picker">
            {showHours && (
                <div className="time-picker-element">
                    <div className="plus time-picker-chevron">
                        <ChevronDown onClick={() => onChangeHours(hours + 1)} />
                    </div>
                    <input
                        className="time-picker-input"
                        type="number"
                        value={doubleDigit(hours)}
                        onChange={(e) => onChangeHours(+e.target.value)}
                    />
                    <div className="minus time-picker-chevron">
                        <ChevronDown onClick={() => onChangeHours(hours - 1)} />
                    </div>
                </div>
            )}
            {showHours && ':'}
            <div className="time-picker-element">
                <div className="plus time-picker-chevron">
                    <ChevronDown onClick={() => onChangeMinutes(minutes + 1)} />
                </div>
                <input
                    className="time-picker-input"
                    type="number"
                    value={doubleDigit(minutes)}
                    onChange={(e) => onChangeMinutes(+e.target.value)}
                />
                <div className="minus time-picker-chevron">
                    <ChevronDown onClick={() => onChangeMinutes(minutes - 1)} />
                </div>
            </div>
            {showSeconds && ':'}
            {showSeconds && (
                <div className="time-picker-element">
                    <div className="plus time-picker-chevron">
                        <ChevronDown onClick={() => onChangeSeconds(seconds + 1)} />
                    </div>
                    <input
                        className="time-picker-input"
                        type="number"
                        value={doubleDigit(seconds)}
                        onChange={(e) => onChangeSeconds(+e.target.value)}
                    />
                    <div className="minus time-picker-chevron">
                        <ChevronDown onClick={() => onChangeSeconds(seconds - 1)} />
                    </div>
                </div>
            )}
        </div>
    );
};

export default TimePicker;

export const getHours = (value: number) => Math.floor(value / 3600);
export const getMinutes = (value: number) => Math.floor((value % 3600) / 60);
export const getSeconds = (value: number) => Math.floor(value % 60);
