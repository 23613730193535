import { TypeOfFeatures } from '_types';
import urheilupuistoFeatures from './urheilupuistoFeatures';
import rautatientoriFeatures from './rautatientoriFeatures';

const features: {
    [key: string]: TypeOfFeatures;
} = {};

export const stations = [
    'Kivenlahti',
    'Espoonlahti',
    'Soukka',
    'Kaitaa',
    'Finnoo',
    'Matinkylä',
    'Niittykumpu',
    'Urheilupuisto',
    'Tapiola',
    'Aalto yliopisto',
    'Keilaniemi',
    'Koivusaari',
    'Lauttasaari',
    'Ruoholahti',
    'Kamppi',
    'Rautatientori',
    'Helsingin yliopisto',
    'Hakaniemi',
    'Sörnäinen',
    'Kalasatama',
    'Kulosaari',
    'Herttoniemi',
    'Siilitie',
    'Itäkeskus',
    'Myllypuro',
    'Kontula',
    'Mellunmäki',
    'Puotila',
    'Rastila',
    'Vuosaari',
];

features['urheilupuisto'] = urheilupuistoFeatures;

features['rautatientori'] = rautatientoriFeatures;

export default features;
