import { Viewport } from './viewport';
import { StaticAssets } from './viz_staticAssets';
import { CameraAssets } from './viz_cameraAssets';
import { Layers } from './viz_layers';
import { AnalysisAssets } from './viz_analysis';

export class Visualization {
    vpt: Viewport;
    static: StaticAssets;
    camera: CameraAssets;
    layers: Layers;
    analysis: AnalysisAssets;

    constructor(
        vpt: Viewport,
        params: {
            envMapUrl: string;
            modelUrl: string;
        }
    ) {
        this.vpt = vpt;

        this.vpt.dependencies.set('visualization', this);

        this.static = new StaticAssets(vpt, params.envMapUrl);
        this.vpt.dependencies.set('static', this.static);
        this.analysis = new AnalysisAssets(vpt);
        this.vpt.dependencies.set('analysis', this.analysis);
        this.layers = new Layers(vpt);
        this.vpt.dependencies.set('layers', this.layers);
        this.camera = new CameraAssets(vpt);
        this.vpt.dependencies.set('camera', this.camera);
    }
}
