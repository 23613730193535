import React from 'react';
import moment from 'moment';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    selectErrorCheckingStatus,
    selectErrorFetching,
    selectErrorGenerating,
    selectPlaybackEndMs,
    selectPlaybackStartMs,
    setConfirmTimes,
    setPlaybackEndMs,
    setPlaybackStartMs,
} from 'features/StationView/PlaybackViewSlice';
import DatePicker from 'components/UIComponents/DatePicker/DatePicker';
import TimePicker from 'components/UIComponents/TimePicker/TimePicker';
import { Button } from 'components/UIComponents/Button/Button';
import { ReactComponent as WarningIcon } from '../../assets/icons/ico-warning.svg';

const PlayBackComponents: React.FC = () => {
    const dispatch = useAppDispatch();

    const [startMs, endMs] = [
        useAppSelector(selectPlaybackStartMs),
        useAppSelector(selectPlaybackEndMs),
    ];

    const [startTimestamp, setStartTimestamp] = React.useState<number>(startMs);
    const [endTimestamp, setEndTimestamp] = React.useState<number>(endMs);

    React.useEffect(() => setStartTimestamp(startMs), [startMs]);
    React.useEffect(() => setEndTimestamp(endMs), [endMs]);

    const [wrongDatesAlert, setWrongDatesAlert] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (
            moment(startTimestamp).format('L') === moment(endTimestamp).format('L') &&
            moment(endTimestamp).diff(moment(startTimestamp)) < 3600000
        )
            setWrongDatesAlert(false);
        else setWrongDatesAlert(true);
    }, [startTimestamp, endTimestamp]);

    const confirmTimes = () => {
        if (
            moment(startTimestamp).format('L') === moment(endTimestamp).format('L') &&
            moment(endTimestamp).diff(moment(startTimestamp)) < 3600000
        ) {
            dispatch(setPlaybackStartMs(startTimestamp));
            dispatch(setPlaybackEndMs(endTimestamp));
            dispatch(setConfirmTimes(true));
        }
    };

    const eSetStartTimestamp = (timestampMs: number) => {
        setStartTimestamp(timestampMs);
        setEndTimestamp(
            (eMs) =>
                moment(eMs).hour() * 3600000 +
                moment(eMs).minute() * 60000 +
                moment(timestampMs).startOf('day').valueOf()
        );
    };
    const eSetEndTimestamp = (timestampMs: number) => {
        setEndTimestamp(timestampMs);
        setStartTimestamp(
            (sMs) =>
                moment(sMs).hour() * 3600000 +
                moment(sMs).minute() * 60000 +
                moment(timestampMs).startOf('day').valueOf()
        );
    };

    const errorGenerating = useAppSelector(selectErrorGenerating);
    const errorCheckingStatus = useAppSelector(selectErrorCheckingStatus);
    const errorFetching = useAppSelector(selectErrorFetching);

    return (
        <>
            <div className="playback-stats stats-group">
                <div className="playback stats-data">
                    <label htmlFor="playback">
                        <span>Playback</span>
                    </label>
                    <PlaybackDateTimePicker
                        timestampMs={startTimestamp}
                        setTimeStamp={eSetStartTimestamp}
                    />

                    <PlaybackDateTimePicker
                        timestampMs={endTimestamp}
                        setTimeStamp={eSetEndTimestamp}
                    />
                    <div className="go-button-container">
                        <Button
                            disabled={wrongDatesAlert}
                            onClick={() => {
                                if (startTimestamp < endTimestamp && !wrongDatesAlert) {
                                    confirmTimes();
                                }
                            }}>
                            CONFIRM
                        </Button>
                    </div>
                    <div style={{ marginTop: '1rem', fontSize: '1.3rem' }}>
                        If you leave the page the loaded data will be reset
                    </div>
                    {wrongDatesAlert && (
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '2rem',
                                marginTop: '2rem',
                            }}>
                            <div style={{ width: '24px', height: '24px' }}>
                                <WarningIcon fill="var(--error)" />
                            </div>
                            <div>PLAYBACK IS ONLY AVAILABLE FOR A MAXIMUM INTERVAL OF ONE HOUR</div>
                        </div>
                    )}
                </div>
            </div>
            {(errorGenerating || errorCheckingStatus) && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '2rem',
                    }}>
                    <div style={{ width: '24px', height: '24px' }}>
                        <WarningIcon fill="var(--error)" />
                    </div>
                    <div>NO DATA AVAILABLE FOR THE SELECTED INTERVAL</div>
                </div>
            )}
            {errorFetching && (
                <div
                    style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '2rem',
                    }}>
                    <div style={{ width: '24px', height: '24px' }}>
                        <WarningIcon fill="var(--error)" />
                    </div>
                    <div>UNEXPECTED ERROR OCCURRED WHILE LOADING THE DATA</div>
                </div>
            )}
        </>
    );
};

const PlaybackDateTimePicker: React.FC<{
    timestampMs: number;
    setTimeStamp: (ms: number) => void;
}> = ({ timestampMs, setTimeStamp }) => {
    const [date, setDate] = React.useState(new Date(timestampMs));
    const [time, setTime] = React.useState<number>(
        moment(timestampMs).hour() * 3600 + moment(timestampMs).minutes() * 60
    );

    React.useEffect(() => {
        setDate(new Date(timestampMs));
        setTime(moment(timestampMs).hour() * 3600 + moment(timestampMs).minutes() * 60);
    }, [timestampMs]);

    React.useEffect(() => {
        setTimeStamp(moment(date).startOf('day').valueOf() + time * 1000);
    }, [date, time]);

    return (
        <div className="start DateTimePicker-container">
            <DatePicker dateValue={date} setDateValue={setDate} maxDate={new Date()} />
            <TimePicker value={time} onChange={setTime} showSeconds={false} />
        </div>
    );
};

export default PlayBackComponents;
