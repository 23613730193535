import React from 'react';
import { Viewport } from './viewport';
import { Visualization } from './visualization';

import './visualizer.scss';
import { useAppDispatch } from 'app/hooks';
import { setCameraViews, setCurrentCameraView } from 'features/StationView/StationViewSlice';
import { useGetDigiTwinUIAPIQuery } from 'app/store';
import { Views } from '_types';
import _ from 'lodash';

const params = {
    envMapUrl: '/content/potsdamer_platz_2k.hdr',
    modelUrl: '/content/FullStation.glb',
    blobUrl: '/content/dataBlob.fbx',
};

export class VizStore {
    public visualization: Visualization;
    private static instance: VizStore;

    private constructor(viz) {
        this.visualization = viz;
        VizStore.instance = this;
    }

    public static setInstance(viz) {
        VizStore.instance = new VizStore(viz);
    }

    public static getInstance(): VizStore {
        return VizStore.instance;
    }

    public static setView(viewKey: string) {
        console.log('Instance viz', this.instance.visualization);
        console.log('Incoming key', viewKey);
        this.instance.visualization.camera.api.SetViewPoint(viewKey);
    }
}

interface Props {
    stationName: string;
    async?: boolean;
    analysis?: boolean;
}

const Visualizer: React.FC<Props> = (props) => {
    const { stationName, async = false, analysis = false } = props;
    const dispatch = useAppDispatch();
    const [modelLoaded, toggleModelLoaded] = React.useState(false);
    const wrapperRef = React.createRef<HTMLDivElement>();

    const urlsQuery = useGetDigiTwinUIAPIQuery(
        'stations/' + stationName.toLowerCase() + '/3dassets'
    );
    const viewsQuery = useGetDigiTwinUIAPIQuery(
        'stations/' + stationName.toLowerCase() + (analysis ? '/analysisviews' : '/cameraviews')
    );
    const equipmentsQuery = useGetDigiTwinUIAPIQuery(
        'stations/' + stationName.toLowerCase() + '/equipments'
    );

    React.useEffect(() => {
        console.log('Initial UseEffect');
        const element = wrapperRef.current;

        if (element && !modelLoaded) {
            const vpt = new Viewport(
                element,
                element.offsetWidth,
                element.offsetHeight,
                stationName
            );
            const viz = new Visualization(vpt, params);

            VizStore.setInstance(viz);

            /* if (analysis) {
                // vpt.controls.enabled = false;
                viz.analysis.AddPlanes();
            } */
        }
    }, []);

    React.useEffect(() => {
        if (
            urlsQuery.status !== 'fulfilled' ||
            viewsQuery.status !== 'fulfilled' ||
            equipmentsQuery.status !== 'fulfilled'
        )
            return;

        const viz = VizStore.getInstance().visualization;
        const urls = urlsQuery.data?.assets;
        const viewsSelector = analysis ? 'analysis_views' : 'camera_views';
        const viewQueryResults = viewsQuery.data?.[viewsSelector];
        const views: Views = new Map(
            viewQueryResults.map((viewResult) => {
                const value = _.cloneDeep(viewResult);
                value.identifier = undefined;
                return [viewResult.identifier, value];
            })
        );

        dispatch(setCameraViews(views ?? new Map()));

        if (async && urlsQuery.status === 'fulfilled') {
            Load(
                0,
                urls,
                async (url: string) => {
                    return viz.static.api.LoadModel(url, equipmentsQuery?.data.equipments.trains);
                },
                () => {
                    Array.from(views?.entries() ?? []).forEach(([k, v]) => {
                        viz.camera.api.AddViewPoint(k, v);

                        if (v.default) {
                            viz.camera.api.SetViewPoint(k);
                            dispatch(setCurrentCameraView({ key: k, label: v.label }));
                        }
                    });

                    toggleModelLoaded(true);
                }
            );
        } else {
            urls.forEach((url) =>
                viz.static.api.LoadModel(url, equipmentsQuery?.data.equipments.trains)
            );

            Array.from(views?.entries() ?? []).forEach(([k, v]) => {
                viz.camera.api.AddViewPoint(k, v);

                if (v.default) {
                    viz.camera.api.SetViewPoint(k);
                    dispatch(setCurrentCameraView({ key: k, label: v.label }));
                }
            });

            toggleModelLoaded(true);
        }
    }, [urlsQuery, viewsQuery, equipmentsQuery]);

    window.addEventListener('resize', () => {
        if (wrapperRef.current) {
            VizStore.getInstance().visualization.vpt.setSize(
                wrapperRef.current.offsetWidth,
                wrapperRef.current.offsetHeight
            );
        }
    });

    return <div id="visualizer" ref={wrapperRef} />;
};

export default Visualizer;

const Load = (
    idx: number,
    urls: string[],
    callback: (url: string) => Promise<void>,
    finalCallback: () => void
) => {
    if (idx >= urls.length) {
        finalCallback();
        return;
    }
    callback(urls[idx]).then(() => Load(idx + 1, urls, callback, finalCallback));
};
