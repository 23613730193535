import React from 'react';
import './pill.scss';

interface PillProps extends React.HTMLProps<HTMLDivElement> {
    label: string;
    active: boolean;
    alert?: boolean;
    disabled?: boolean;
    secondary?: boolean;
}

export const Pill: React.FC<PillProps> = (props) => {
    const { label, active, disabled, secondary, alert, ...rest } = props;
    return (
        <div className={'pillContainer'} {...rest}>
            <div
                className={
                    'pill ' +
                    (active ? 'active ' : '') +
                    (secondary ? 'secondary ' : '') +
                    (disabled ? 'disabled' : '')
                }>
                {label}
            </div>
            {alert && <div className="alert-notification" />}
        </div>
    );
};

interface PillsProps extends React.HTMLProps<HTMLDivElement> {
    options: string[];
    eOnChange: (value: string) => void;
    alerts?: string[];
    active?: string;
    disabled?: boolean;
}

export const Pills: React.FC<PillsProps> = (props) => {
    const { options, eOnChange, active, alerts, ...rest } = props;

    return (
        <>
            {options.map((s) => (
                <Pill
                    key={s}
                    label={s}
                    active={active === s}
                    alert={alerts?.includes(s)}
                    onClick={() => eOnChange(s)}
                    {...rest}
                />
            ))}
        </>
    );
};

type MultiPillsProps = React.HTMLProps<HTMLDivElement> & {
    options: Record<string, boolean>;
    eOnChange: (value: string) => void;
    alerts?: string[];
    disabled?: boolean;
    secondary?: boolean;
};

export const MultiPills: React.FC<MultiPillsProps> = (props) => {
    const { options, eOnChange, alerts, ...rest } = props;

    return (
        <>
            {Object.entries(options).map(([s, value]) => (
                <Pill
                    key={s}
                    label={s}
                    active={value}
                    onClick={() => eOnChange(s)}
                    alert={alerts?.includes(s)}
                    {...rest}
                />
            ))}
        </>
    );
};
