import React from "react";
import ReactEcharts from "echarts-for-react"; 
import { GETPEOPLECOUTDATAMONTHLY } from "_queries";
import { useQuery } from "@apollo/client";

function TimeLineChart(Monthly_data) {  
  const sumsByHourAndReason = {};
  // Monthly_data?.forEach(item => {
  //      const { count, event_date, traffic_types } = item;
  //      if (!sumsByHourAndReason[event_date]) {
  //        sumsByHourAndReason[event_date] = {};
  //      }
  //      if (!sumsByHourAndReason[event_date][traffic_types]) {
  //        sumsByHourAndReason[event_date][traffic_types] = parseInt(count);
  //      } else {
  //        sumsByHourAndReason[event_date][traffic_types] += parseInt(count);
  //      }
  //    });

  //  interface DayCountItem {
  //     outgoing?: string; // Assuming 'human' can be a string or undefined
  //     incoming?: string;  // Assuming 'human' can be a string or undefined
  //      // Assuming 'motorcycle/bicycle' can be a string or undefined
  //      // Define other properties if needed
  //    }
  //  const hour = Object.keys(sumsByHourAndReason)
  //  const day_count :DayCountItem[]= Object.values(sumsByHourAndReason)
  //  const human=day_count.map(item => item?.outgoing )
  //  const motor_cycle=day_count.map(item => item?.incoming)
  //  const sumTwoLists = (human, motor_cycle) => human.map((item, index) => item + motor_cycle[index]);

  
  const count=Monthly_data?.map(item => item?.count )
  const event_date=Monthly_data?.map(item => item?.event_date )

  const option = { 
	// tooltip: {
  //   trigger: 'axis',
  //   position: function (pt) {
  //     return [pt[0], '10%'];
  //   }
  // },
  color: ['#7296F9','#AAE1C8',],
  title: {
    
  },
  // toolbox: {
  //   /*feature: {
  //     dataZoom: {
  //       yAxisIndex: 'none'
  //     },
  //     restore: {},
  //     saveAsImage: {}
  //   }*/
  // },
  xAxis: {
    type: 'category',
    boundaryGap: true,
    data: event_date,axisLabel: {
      textStyle: {
        color: '#fff', // Label text color
        fontSize: 10,
      },
    },
  },
  yAxis: {axisLabel: {textStyle: {color: '#fff',fontSize: 10},} ,
    type: 'value',
    boundaryGap: ['1%', '100%']
  },
  dataZoom: [
    {
      type: 'inside',

    },
    { 
      type: 'slider'
    }
  ],
  series: [
    {
      name: 'People Count',
      type: 'bar',
      symbol: 'none',
      sampling: 'lttb',large: true,
      
      
      
      data: count
    }
  ]
};

  

    
  return <ReactEcharts option={option} />;

}
  export default TimeLineChart;