import React from 'react';
import { ReactComponent as CaretUpBig } from 'assets/icons/caret-up-big.svg';
import { z } from 'zod';
import CustomToggle from 'components/UIComponents/Toggle/Toggle';

export const ZodEscalatorDirection = z.enum(['up', 'down', 'disabled']);
export type EscalatorDirection = z.infer<typeof ZodEscalatorDirection>;
type EscalatorsTypes = {
    [k: string]: EscalatorDirection;
};
export type EscalatorsSectionState = EscalatorsTypes;
type Props = {
    state: EscalatorsSectionState;
    setState: React.Dispatch<React.SetStateAction<EscalatorsSectionState | undefined>>;
    stationName: string;
};

const EscalatorSection: React.FC<Props> = ({ state, setState, stationName }) => {
    return (
        <div className="equipments-details">
            <div className="equipment-title">Escalators</div>
            <div className="escalators-section">
                {Object.keys(state).map((escalatorName) => {
                    if (typeof state[escalatorName] === 'boolean') return null;
                    return (
                        <EscalatorDetail
                            key={escalatorName}
                            label={escalatorName.replace('escalator_', ' ')}
                            value={state[escalatorName] as unknown as EscalatorDirection}
                            onChange={(value) =>
                                setState((s) => ({ ...s, [escalatorName]: value }))
                            }
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default EscalatorSection;

type EscalatorDetailType = {
    label: string;
    value: EscalatorDirection;
    onChange: (value: EscalatorDirection) => void;
};

const EscalatorDetail: React.FC<EscalatorDetailType> = ({ label, value, onChange }) => {
    return (
        <div className="escalator-detail">
            <div className="escalator-detail-first-row">
                <div className="escalator-detail-title">{label}</div>
                <CustomToggle
                    checked={value !== 'disabled'}
                    onChange={() => onChange(value === 'disabled' ? 'up' : 'disabled')}
                />
            </div>
            <div className="escalator-direction-container">
                <div className={`escalator-direction ${value}`} onClick={() => onChange('up')}>
                    <CaretUpBig fill={value === 'up' ? 'white' : 'black'} />
                </div>
                <div
                    className={`escalator-direction rotate ${value}`}
                    onClick={() => onChange('down')}>
                    <CaretUpBig fill={value === 'down' ? 'white' : 'black'} />
                </div>
            </div>
        </div>
    );
};
