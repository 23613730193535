import { DataPosition } from '_types';
import { Visualization } from 'components/Visualizer/visualization';
import { getCloserSmallerNumber, getElevatorNameFromId } from './util';
import { apolloClient } from 'app/apolloClient';
import { GENERATEPLAYBACKFILE, GETPLAYBACKCHUNCK, GETPLAYBACKFILESTATUS } from '_queries';
import _ from 'lodash';
import { ApolloClient, NormalizedCacheObject } from '@apollo/client';
import { PlaybackDataType, PlaybackElevatorsPositions } from '_types/playback';
import { PlaybackDocument } from '_types/queries';
import { MetadataElevator } from '_types/features';
import { store } from 'app/store';
import {
    setConfirmTimes,
    setErrorCheckingStatus,
    setErrorFetching,
    setErrorGenerating,
    setPlaybackReachedMs,
} from 'features/StationView/PlaybackViewSlice';
import * as THREE from 'three';

export class PlaybackDataManager {
    stationName: string;

    viz: Visualization;
    client: ApolloClient<NormalizedCacheObject>;

    peopleData1: PlaybackDataType;
    peopleData2: PlaybackDataType;
    elevatorsPositions: PlaybackElevatorsPositions;

    stationsElevators: MetadataElevator[];
    startMs: number;
    endMs: number;
    trainMovement: {
        east_metro: {
            [timestamp: string]: {
                direction: 'to_east' | 'from_west';
                percentage: number;
            };
        };
        west_metro: {
            [timestamp: string]: {
                direction: 'from_east' | 'to_west';
                percentage: number;
            };
        };
    };

    stopQuerying = false;

    constructor(stationName: string) {
        this.client = apolloClient;
        this.endMs = 0;
        this.stationName = stationName;
    }

    public SetVisualization(viz: Visualization, elevators: MetadataElevator[]) {
        this.stationsElevators = elevators;
        this.viz = viz;

        const color = new THREE.Color(0x1ed273);
        elevators?.forEach((elevator) =>
            viz.static.SetAssetsFeatures([
                {
                    name: elevator.name,
                    color,
                },
                {
                    name: elevator.shaft ?? '',
                    color,
                },
            ])
        );
    }

    public StartPlaybackFileCreation(
        startMs: number,
        endMs: number,
        onResolutionCallback: () => void
    ) {
        this.stopQuerying = true;
        store.dispatch(setErrorGenerating(false));
        store.dispatch(setErrorCheckingStatus(false));
        store.dispatch(setErrorFetching(false));

        console.log('query');

        this.startMs = startMs;
        this.endMs = endMs;
        this.peopleData1 = {};
        this.peopleData2 = {};
        this.elevatorsPositions = Object.fromEntries(
            this.stationsElevators?.map((elevator) => [elevator.name, {}]) ?? []
        );
        this.trainMovement = {
            east_metro: {},
            west_metro: {},
        };

        this.client
            .query({
                query: GENERATEPLAYBACKFILE,
                variables: {
                    endS: Math.floor(endMs / 1000) + '',
                    startS: Math.floor(startMs / 1000) + '',
                    userName: store.getState().auth.user?.getUsername(),
                    site:
                        this.stationName[0].toUpperCase() + this.stationName.slice(1).toLowerCase(),
                },
            })
            .catch(() => {
                store.dispatch(setErrorGenerating(true));
                store.dispatch(setConfirmTimes(false));
            });

        setTimeout(() => {
            this.stopQuerying = false;
            this.GetFileProgress(onResolutionCallback);
        }, 2000);
    }

    public GetFileProgress(onResolutionCallback: () => void) {
        this.client
            .query({
                query: GETPLAYBACKFILESTATUS,
                variables: {
                    userName: store.getState().auth.user?.getUsername(),
                },
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                if (store.getState().playbackView.errorGenerating) return;
                if (res.data.getPlaybackProgress.current_status === 'Completed') {
                    store.dispatch(setPlaybackReachedMs(this.startMs));
                    this.QueryBatch(0)?.then(() => this.setSlice(this.startMs));
                    onResolutionCallback();
                } else {
                    setTimeout(() => this.GetFileProgress(onResolutionCallback), 1000);
                }
            })
            .catch(() => {
                store.dispatch(setErrorCheckingStatus(true));
                store.dispatch(setConfirmTimes(false));
            });
    }

    private QueryBatch(index: number) {
        if (this.stopQuerying) return;

        console.log(`index ${index}`);
        return this.client
            .query({
                query: GETPLAYBACKCHUNCK,
                variables: {
                    lastIndex: index,
                    userName: store.getState().auth.user?.getUsername(),
                },
                fetchPolicy: 'no-cache',
            })
            .then((res) => {
                const dataString = res.data.StreamPaybackData.body;
                if (dataString === '{}') return;

                const data: PlaybackDocument = JSON.parse(dataString);

                // const firstMomentMs = Math.min(...Object.keys(data).map((s) => +s));
                const lastMomentMs = Math.max(...Object.keys(data).map((s) => +s));

                Object.entries(data).forEach(([timestampMs, agents]) => {
                    agents.map((agent) => {
                        if (agent.sensor_id === 'Kompassitaso') {
                            this.peopleData1[timestampMs] = this.peopleData1[timestampMs] || [];
                            this.peopleData1[timestampMs].push({
                                x: agent.x,
                                y: agent.y,
                                z: agent.z,
                            });
                        } else if (agent.sensor_id === 'Asematunneli') {
                            this.peopleData2[timestampMs] = this.peopleData2[timestampMs] || [];
                            this.peopleData2[timestampMs].push({
                                x: agent.x,
                                y: agent.y,
                                z: agent.z,
                            });
                        } else if (agent.sensor_id === 'ELEVATOR') {
                            this.elevatorsPositions[
                                getElevatorNameFromId(+agent.ObjectID, this.stationsElevators)
                            ][timestampMs] = { x: agent.x, y: agent.y, z: agent.z };
                        } else if (agent.sensor_id === 'METRO') {
                            for (let i = 0; i < 50; i++) {
                                const isIncoming = agent.x === '1';
                                const timestamp =
                                    +timestampMs + (isIncoming ? -i * 100 + 3000 : i * 100);
                                const percentage = isIncoming ? 1 - i / 49 : i / 49;
                                const direction =
                                    agent.ObjectID === '0'
                                        ? isIncoming
                                            ? 'from_west'
                                            : 'to_east'
                                        : isIncoming
                                        ? 'from_east'
                                        : 'to_west';

                                this.trainMovement[
                                    agent.ObjectID === '0' ? 'east_metro' : 'west_metro'
                                ][timestamp] = {
                                    direction,
                                    percentage,
                                };
                            }
                        }
                    });
                });

                store.dispatch(setPlaybackReachedMs(lastMomentMs));

                if (res.data.StreamPaybackData.next_index) {
                    this.QueryBatch(res.data.StreamPaybackData.next_index);
                }
            })
            .catch(() => {
                store.dispatch(setErrorFetching(false));
                store.dispatch(setConfirmTimes(false));
            });
    }

    public setSlice(timestampMs: number) {
        const people1SliceTimestamp = getCloserSmallerNumber(
            Object.keys(this.peopleData1) as unknown as number[],
            timestampMs
        );
        const people2SliceTimestamp = getCloserSmallerNumber(
            Object.keys(this.peopleData2) as unknown as number[],
            timestampMs
        );
        const people1CurrentSlice: DataPosition[] | undefined =
            this.peopleData1[people1SliceTimestamp];
        const people2CurrentSlice: DataPosition[] | undefined =
            this.peopleData2[people2SliceTimestamp];
        const peopleCurrentPositions: DataPosition[] = [];

        if (people1CurrentSlice) peopleCurrentPositions.push(...people1CurrentSlice);
        if (people2CurrentSlice) peopleCurrentPositions.push(...people2CurrentSlice);

        const peopleAsset =
            peopleCurrentPositions.map((o) => {
                const obj = { position: { x: +o.x, y: +o.y, z: +o.z } };
                return obj;
            }) ?? [];

        this.viz.layers.people.UpdateMeshes(peopleAsset);

        Object.entries(this.elevatorsPositions).forEach(([elevatorName, positions]) => {
            const elevatorTimestamp = getCloserSmallerNumber(
                Object.keys(positions) as unknown as number[],
                timestampMs
            );
            const currentPosition = positions[elevatorTimestamp];
            if (currentPosition) {
                this.viz.static.SetAssetsFeatures([
                    {
                        name: elevatorName,
                        position: currentPosition,
                    },
                ]);
            }
        });

        for (const trainDirection in this.trainMovement) {
            const trainTimestamp = getCloserSmallerNumber(
                Object.keys(this.trainMovement[trainDirection]) as unknown as number[],
                timestampMs
            );

            const trainPosition = this.trainMovement[trainDirection][trainTimestamp];

            if (trainPosition) {
                // console.log(trainPosition.direction, trainPosition.percentage);

                this.viz.static.trainAssets.UpdateTrainStatus(
                    trainPosition.direction,
                    trainPosition.percentage
                );
            } else {
                this.viz.static.trainAssets.UpdateTrainStatus(
                    trainDirection === 'east_metro' ? 'from_west' : 'from_east',
                    0
                );
            }
        }
    }
}
