import React from 'react';
import { BsFillPauseFill, BsFillPlayFill } from 'react-icons/bs';
import './playbackSlider.scss';
import moment from 'moment';
import Spinner from 'components/LoadingPage/Spinner';

interface PlaybackSliderProps {
    startMs: number;
    endMs: number;
    reachedMs: number;
    onInputChange: (input: number) => void;
    isLoading?: boolean;
    showLabels?: boolean;
    topper?: React.ReactNode;
    inputInterval?: number;
}

const NewPlaybackSlider: React.FC<PlaybackSliderProps> = (props) => {
    const {
        startMs,
        endMs,
        reachedMs,
        onInputChange,
        isLoading,
        showLabels,
        inputInterval,
        topper,
    } = props;

    const [inputValue, setInputValue] = React.useState<number>(startMs);
    React.useEffect(() => setInputValue(startMs), [startMs]);
    const [isPlayButtonStopped, setIsPlayButtonStopped] = React.useState<boolean>(true);

    const onChange = (input: number) => {
        onInputChange(input);
        setInputValue(input);
    };

    React.useEffect(() => {
        const playButtonInterval = setInterval(() => {
            if (!isPlayButtonStopped) {
                setInputValue((input) => {
                    onInputChange(input + 100);
                    return input + 100;
                });
            }
        }, inputInterval ?? 100);

        return () => clearInterval(playButtonInterval);
    }, [isPlayButtonStopped]);

    return (
        <div className="timeline">
            {isLoading ? (
                <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                    <Spinner />
                </div>
            ) : (
                <>
                    <button
                        onClick={() => setIsPlayButtonStopped(!isPlayButtonStopped)}
                        className="play-stop-button">
                        {isPlayButtonStopped ? (
                            <BsFillPlayFill size="30px" />
                        ) : (
                            <BsFillPauseFill size="30px" />
                        )}
                    </button>
                    <div className="data">
                        {showLabels && (
                            <div className="labels">
                                <span>
                                    {moment().startOf('day').valueOf() ===
                                    moment(startMs).startOf('day').valueOf()
                                        ? moment(startMs).format('HH:mm')
                                        : moment(startMs).format('DD-MM  HH:mm')}
                                </span>
                                <span>
                                    {moment().startOf('day').valueOf() ===
                                    moment(endMs).startOf('day').valueOf()
                                        ? moment(endMs).format('HH:mm')
                                        : moment(endMs).format('DD-MM  HH:mm')}
                                </span>
                            </div>
                        )}
                        {topper}
                        <div className="axis">
                            <input
                                type="range"
                                min={startMs}
                                max={reachedMs}
                                value={inputValue}
                                onChange={(e) => {
                                    onChange(+e.target.value);
                                }}
                                style={{
                                    width: `${((reachedMs - startMs) * 100) / (endMs - startMs)}%`,
                                    background:
                                        'linear-gradient(to right, var(--kone-blue) 0%, var(--kone-blue) ' +
                                        ((inputValue - startMs) * 100) / (reachedMs - startMs) +
                                        '%, white ' +
                                        ((inputValue - startMs) * 100) / (endMs - startMs) +
                                        '%, white 100%)',
                                }}
                            />
                        </div>
                    </div>
                </>
            )}
        </div>
    );
};

export default NewPlaybackSlider;
