import React from 'react';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import { useAppDispatch } from 'app/hooks';
import './DashBoard.scss';
import './index.scss';
import BarChart from './BarChart';
import BarChartBike from './BarChartBike';
import StackedBarChart from './StackedBarChart';
import { useQuery } from '@apollo/client';
import {
    GETJOURNEYTIME,
    GETMETROZONESDATA,
    GETEQUIPMENTUSAGE,
    GETPEOPLECOUTDATABYWEEK,
    GETPEOPLECOUTDATABYMONTH,
    GETTOTALCOUNTSINOUT,
    GETPEOPLECOUTDATADAILYBYHOUR,
} from '_queries';
import { LuBike } from 'react-icons/lu';
import { FaWalking } from 'react-icons/fa';
import { MainViewNavBar } from 'components/MainViewNavBar/MainViewNavBar';
import WagonChartEast from './WagonChartEast';
import WagonChartWest from './WagonChartWest';
import TimeLineChart from './TimeLineChart';
import StackedBarChartWeekly from './StackedBarChartWeekly';
import StackedBarChartWeeklybike from './StackedBarChartWeeklybike';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import { secToMins } from 'app/util';
import BarGraphWithAverage from 'components/Graphs/BarGraphWithAverage';
import BarGraphWithTimeline from 'components/Graphs/BarGraphWithTimeline';
import EnergyConsumptionMonthlyGraph from './EnergyConsumptionMontlyGraph';
import JourneyTimeMonthlyGraph from './JourneyTimeMonthly';

const DashBoard: React.FC = () => {
    const { data: dailyData, loading: dailyDataLoading } = useQuery(GETPEOPLECOUTDATADAILYBYHOUR);
    const { data: weeklyData, loading: weeklyDataLoading } = useQuery(GETPEOPLECOUTDATABYWEEK);
    const { data: monthlyData, loading: monthlyDataLoading } = useQuery(GETPEOPLECOUTDATABYMONTH);
    const { data: journeytime, loading: journeytimeloading } = useQuery(GETJOURNEYTIME);
    const { data: equipmentUsage, loading: equipmentUsageloading } = useQuery(GETEQUIPMENTUSAGE);
    const { data: peole_bike_data, loading: peole_bike_dataloading } =
        useQuery(GETTOTALCOUNTSINOUT);
    const { data: metroZoneData, loading: metroZoneDataLoading } = useQuery(GETMETROZONESDATA);

    const Daily_data = dailyData?.getPeopleCountDataRDS;
    const Weekly_data = weeklyData?.weeklyDatapeople;
    const Monthly_data = monthlyData?.MontlyDataPeople;
    const Equipment_data_human = equipmentUsage?.getEquipmentList[0]?.human;
    const Equipment_data_bike = equipmentUsage?.getEquipmentList[0]?.bike;
    const IncomingBikecount = peole_bike_data?.getTotalCountsInOut[0].incoming_bike_count;
    const OutgoingBikecount = peole_bike_data?.getTotalCountsInOut[0].outgoing_bike_count;
    const TotalBikes = (IncomingBikecount || 0) + (OutgoingBikecount || 0);
    const IncomingPeoplecount = peole_bike_data?.getTotalCountsInOut[0].incoming_people_count;
    const OutgoingPeoplecount = peole_bike_data?.getTotalCountsInOut[0].outgoing_people_count;
    const TotalPeople = (IncomingPeoplecount || 0) + (OutgoingPeoplecount || 0);
    const EmetroData: string[] = metroZoneData?.getMetroZoneData?.Emetro;
    const WmetroData: string[] = metroZoneData?.getMetroZoneData?.Wmetro;

    const incoming_journey = secToMins(
        journeytime?.listLiveJourneyEvents.items
            .filter((o) => o.traffic_type === 'incoming')
            .reduce((n, o) => n + o.avg_journey_time_s, 0) / 2
    );
    const outgoing_journey = secToMins(
        journeytime?.listLiveJourneyEvents.items
            .filter((o) => o.traffic_type === 'outgoing')
            .reduce((n, o) => n + o.avg_journey_time_s, 0) / 2
    );
    const sumInThousands = (TotalPeople / 1000).toFixed(1);
    // Barchart Data

    interface DayCountItem {
        outgoing?: string; // Assuming 'human' can be a string or undefined
        incoming?: string; // Assuming 'motorcycle/bicycle' can be a string or undefined
        // Define other properties if needed
    }
    const dispatch = useAppDispatch();
    React.useEffect(() => {
        dispatch(setMainSidebarFull(false));
    }, []);

    const metroXMax =
        EmetroData && WmetroData
            ? Math.ceil(
                  Math.max(
                      ...Object.values(EmetroData)
                          .concat(Object.values(WmetroData))
                          .map((s) => +s)
                  ) / 100
              ) * 100
            : undefined;

    /* return (
        <div className="dashboard-container">
            <div className="row">
                <JourneyTimeMonthlyGraph />
                <EnergyConsumptionMonthlyGraph />
            </div>
        </div>
    ); */

    return weeklyDataLoading ? (
        <LoadingPage />
    ) : (
        <div className="dashboard-container">
            <div className="row">
                <div className="widget-top-row">
                    <div>
                        <span className="title"> Incoming Journey Time </span> <br />{' '}
                        <div className="values">
                            <span className="values"> &nbsp;{incoming_journey} mins </span>
                        </div>
                    </div>{' '}
                </div>
                <div className="widget-top-row">
                    <div>
                        <span className="title"> Outgoing Journey Time </span> <br />{' '}
                        <div className="values">
                            <span className="values"> &nbsp;{outgoing_journey} mins </span>
                        </div>
                    </div>{' '}
                </div>
                <div className="widget-top-row">
                    <div>
                        <span className="title"> People Count </span> <br />{' '}
                        <div className="values">
                            <span className="values">
                                {' '}
                                <FaWalking className="small-icon" />
                                &nbsp;&nbsp;{TotalPeople} {}
                            </span>
                        </div>
                    </div>{' '}
                </div>
                <div className="widget-top-row">
                    <div>
                        <span className="title"> Bike Users</span> <br />{' '}
                        <div className="values">
                            <span className="values">
                                <LuBike />
                                &nbsp;&nbsp;{TotalBikes} &nbsp;
                            </span>
                        </div>
                    </div>{' '}
                </div>
            </div>
            <div className="row">
                <div className="widget-chart">
                    <div className="title">
                        <p>People flow for today </p>
                    </div>
                    {StackedBarChart(Daily_data)}
                </div>
                <div className="widget-chart">
                    <div className="title">
                        <p>People flow trend</p>
                    </div>
                    {TimeLineChart(Monthly_data)}
                </div>
            </div>
            {/* 
         <MetroEntryExit />
         */}
            <div className="row">
                <div className="widget-chart">
                    <div className="title">
                        <p>People Flow for this Week</p>
                    </div>
                    {StackedBarChartWeekly(Weekly_data)}
                </div>
                <div className="widget-chart">
                    <div className="title">
                        <p>Bikes count for this Week</p>
                    </div>
                    {StackedBarChartWeeklybike(Weekly_data)}
                </div>
            </div>
            <div className="row">
                <div className="widget-chart">
                    <div className="title">
                        <p>Equipment usage - People</p>
                    </div>
                    {BarChart(Equipment_data_human)}
                </div>
                <div className="widget-chart">
                    <div className="title">
                        <p>Equipment usage - Bikes</p>
                    </div>
                    {BarChartBike(Equipment_data_bike)}
                </div>
            </div>
            <div className="row">
                {/* <JourneyTimeMonthlyGraph /> */}
                <EnergyConsumptionMonthlyGraph />
            </div>
            <div className="row">
                <div className="widget-chart">
                    <div className="title">
                        <p>To West Metro </p>
                    </div>
                    {WmetroData != null &&
                        typeof WmetroData !== 'undefined' &&
                        WagonChartWest(WmetroData)}
                </div>
                <div className="widget-chart">
                    <div className="title">
                        <p>To East Metro</p>
                    </div>
                    {EmetroData != null &&
                        typeof EmetroData !== 'undefined' &&
                        WagonChartEast(EmetroData)}
                </div>
            </div>
        </div>
    );
};
export default DashBoard;
