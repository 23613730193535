import { Visualization } from 'components/Visualizer/visualization';
import { LiveEquipmentData } from './liveEquipmentDataManager';
import { LiveTrainData } from './liveTrainDataManager';
import { LayersDataManager } from './layers/layersDataManager';
import { MainLayer } from '_types';
import { MetadataEquipments } from '_types/features';

export class LiveDataManager {
    viz: Visualization;
    dataManagers: {
        equipment: LiveEquipmentData;
        train: LiveTrainData;
    };
    layer: LayersDataManager;

    pollInterval = 200;

    constructor(viz: Visualization, equipments: MetadataEquipments) {
        this.viz = viz;

        this.dataManagers = {
            equipment: new LiveEquipmentData(equipments),
            train: new LiveTrainData(),
        };

        this.layer = new LayersDataManager(viz);
    }

    public SetSubscriptions(pollInterval = this.pollInterval) {
        Object.values(this.dataManagers).forEach((dm) =>
            dm?.SetMainViewSubscription(this.viz, pollInterval)
        );
        this.layer.SetMainLayer('people', pollInterval);
    }

    public SetMainLayer(layerName: MainLayer, pollInterval = this.pollInterval) {
        this.layer.SetMainLayer(layerName, pollInterval);
    }

    public StopSubscription() {
        Object.values(this.dataManagers).forEach((dm) => dm?.StopMainViewSubscription());
        this.layer.StopAssetSubscription();
    }
}
