import { StationParams } from '_types';
import * as THREE from 'three';

export const applyStationParams = (
    obj: THREE.Object3D,
    stationParams: StationParams | undefined
) => {
    if (!stationParams) return;

    if (stationParams.scale) {
        const { x, y, z } = stationParams.scale;
        obj.scale.set(x, y, z);
    }
    if (stationParams.position) {
        const { x, y, z } = stationParams.position;
        obj.position.add(new THREE.Vector3(x, y, z));
    }
    if (stationParams.rotate) {
        const { axis, angle } = stationParams.rotate;
        obj.rotateOnAxis(axis, angle);
    }
};

export const setYAxisVisibility = (yPosition: number, bottom: number, top: number) => {
    return yPosition < top && yPosition > bottom;
};
