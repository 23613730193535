import * as THREE from 'three';
import { Viewport } from './viewport';

export class AnalysisAssets {
    vpt: Viewport;
    scene: THREE.Group;
    material: THREE.MeshPhysicalMaterial = new THREE.MeshPhysicalMaterial({
        transparent: true,
        opacity: 0.7,
        side: THREE.DoubleSide,
    });
    planes: THREE.Mesh<THREE.PlaneGeometry, THREE.MeshPhysicalMaterial>[];

    public constructor(vpt: Viewport) {
        this.vpt = vpt;
        this.scene = new THREE.Group();
        this.planes = [];

        this.vpt.scene.add(this.scene);
    }

    /* public AddPlanes() {
        const [x1, x2] = [183, 222];
        const [z1, z2] = [-140, -180];

        const kompassi_geometry = new THREE.PlaneGeometry(x2 - x1, z2 - z1, 1, 1);

        const kompassi_plane = new THREE.Mesh(kompassi_geometry, this.material);
        kompassi_plane.rotateX(-Math.PI / 2);
        kompassi_plane.rotateZ(Math.PI / 2);
        kompassi_plane.position.set((x1 + x2) / 2, -3.9, (z1 + z2) / 2);

        this.planes.push(kompassi_plane);
        this.scene.add(kompassi_plane);
    }

    public UpdatePlanes(texture: THREE.CanvasTexture) {
        this.material.map = texture;
    } */

    public SetAxisYLimits(bottom?: number, top?: number) {
        this.planes.forEach((plane) => {
            if (plane.position.y < (bottom || -Infinity) || plane.position.y > (top || +Infinity)) {
                plane.material.visible = false;
            } else {
                plane.material.visible = true;
            }
        });
    }
}
