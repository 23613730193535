import { gql } from '@apollo/client';

export const GETSIMULATIONMOVEMENTS = gql`
    query MyQuery($endMs: Int, $startMs: Int, $simulationID: String!) {
        listSimPeopleEvents(startMs: $startMs, endMs: $endMs, simulationID: $simulationID) {
            movements {
                ObjectID
                ObjectType
                position
            }
            time_ms
        }
        listSimMetroEvents(startMs: $startMs, endMs: $endMs, simulationID: $simulationID) {
            time_ms
            items {
                direction
                state
            }
        }
        listSimElevatorEvents(startMs: $startMs, endMs: $endMs, simulationID: $simulationID) {
            time_ms
            items {
                elevator_id
                x
                y
                z
            }
        }
    }
`;

export const GETSIMULATIONKPIS = gql`
    query MyQuery($endMs: Int, $startMs: Int, $simulationID: String!) {
        listSimPeopleCountLevel(startMs: $startMs, endMs: $endMs, simulationID: $simulationID) {
            items {
                floor_name
                passenger_count
            }
            time_ms
        }
    }
`;

export const GETSIMULATIONJOURNEYTIMES = gql`
    query MyQuery(
        $endMs: Int
        $startMs: Int
        $simulationID: String!
        $timeOfDay: String!
        $dayOfWeek: String!
    ) {
        listSimJourneyTimesEvents(startMs: $startMs, endMs: $endMs, simulationID: $simulationID) {
            items {
                avg_journey_time_s
                traffic_type
            }
            time_ms
        }
        listSimAverageWaitingTimes(startMs: $startMs, endMs: $endMs, simulationID: $simulationID) {
            items {
                AWT_s
                equipment_name
            }
            time_ms
        }
        getJourneyTimeEventsRDS(dayOfWeek: $dayOfWeek, timeOfDay: $timeOfDay) {
            avg_incoming_journey_time
            avg_outgoing_journey_time
            date
        }
    }
`;

export const GETSIMULATIONPREPOPULATEDATA = gql`
    query MyQuery($stationname: String!, $weekday: String!, $starthour: Int!, $endhour: Int!) {
        getPrepopulatedSimData(
            stationname: $stationname
            weekday: $weekday
            starthour: $starthour
            endhour: $endhour
        ) {
            from_metro {
                total
                entries {
                    east
                    west
                }
                passenger_groups {
                    bike_users
                    elevators_only
                    normal_users
                    stroller_users
                    wheelchair_users
                }
            }
            to_metro {
                entries {
                    east
                    west
                }
                passenger_groups {
                    bike_users
                    elevators_only
                    normal_users
                    stroller_users
                    wheelchair_users
                }
                total
            }
        }
    }
`;
