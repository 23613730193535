import React from 'react';
import './metroline.scss';

const RoundedConnection: React.FC<{ isInverted?: boolean }> = ({ isInverted }) => {
    return (
        <div className={'rounded-connection-container ' + (isInverted ? 'inverted ' : '')}>
            <div className="top-part">
                <div className="top-connection" />
            </div>
            <div className="middle-part">
                <div className="middle-connection " />
            </div>
            <div className="bottom-part">
                <div className="bottom-connection" />
            </div>
        </div>
    );
};

const Train: React.FC<{ direction: 'east' | 'west'; show: boolean; middle?: boolean }> = ({
    direction,
    show,
    middle,
}) => {
    if (!show) return null;
    return <div className={'train-final ' + (middle ? 'middle ' : '') + direction}></div>;
};

interface MetroLineProps {
    name: string;
    direction: 'east' | 'west';
    trainInStation?: 'M1' | 'M2';
    trainComing?: 'M1' | 'M2';
}

const MetroLineOneLine: React.FC<MetroLineProps> = ({
    name,
    direction,
    trainInStation,
    trainComing,
}) => {
    const M1EastEnd = ['puotila', 'rastila', 'vuosaari'];
    const isM1EastEnd = M1EastEnd.includes(name);
    const M2EastEnd = ['myllypuro', 'kontula', 'mellunmäki'];
    const isM2EastEnd = M2EastEnd.includes(name);
    const isEastLastStation = name === 'vuosaari' || name === 'mellunmäki';
    const isMyllypuro = name === 'myllypuro';
    const isPuotila = name === 'puotila';
    const isKivenlahti = name === 'kivenlahti';
    const noWestLine = isKivenlahti || isMyllypuro;
    const isItäkeskus = name === 'itäkeskus';
    const noEastLine = isEastLastStation || isItäkeskus;

    return (
        <div
            className={
                'metroline-container ' + (isM1EastEnd ? 'M1-east' : isM2EastEnd ? 'M2-east' : '')
            }>
            {isM2EastEnd ? <div className="point hidden" /> : null}
            <div className="point ">
                <Train direction={direction} show={!!trainInStation} />
            </div>

            <div className={'lines-container ' + (isM2EastEnd ? 'wide' : '')}>
                {isM2EastEnd ? (
                    <div className={'single-line-container ' + (isM2EastEnd ? 'M2-east-end' : '')}>
                        {isM2EastEnd ? (
                            <div
                                className={'line ' + (isMyllypuro ? 'myllypuro-connection' : '')}
                            />
                        ) : null}
                    </div>
                ) : null}

                <div className="single-line-container">
                    <div
                        className={
                            'line ' + (noEastLine ? 'east-end' : noWestLine ? 'west-end' : '')
                        }
                    />

                    {(direction === 'west' && (isEastLastStation || isItäkeskus)) ||
                    (direction === 'east' && (isKivenlahti || isMyllypuro || isPuotila)) ? null : (
                        <div className={'middle-point ' + direction}>
                            <Train middle direction={direction} show={!!trainComing} />
                        </div>
                    )}

                    {direction === 'west' && isItäkeskus ? (
                        <>
                            <div className={direction + ' middle-point west-itäkeskus-right'}>
                                <Train middle direction={direction} show={trainComing === 'M2'} />
                            </div>
                            <div className={direction + ' middle-point west-itäkeskus-left'}>
                                <Train middle direction={direction} show={trainComing === 'M1'} />
                            </div>
                        </>
                    ) : null}

                    {direction === 'east' && isMyllypuro ? (
                        <div className={direction + ' middle-point east-myllypuro'}>
                            <Train middle direction={direction} show={!!trainComing} />
                        </div>
                    ) : null}

                    {direction === 'east' && isPuotila ? (
                        <div className={direction + ' middle-point east-puotila'}>
                            <Train middle direction={direction} show={!!trainComing} />
                        </div>
                    ) : null}

                    {isItäkeskus ? (
                        <>
                            <RoundedConnection />
                            <RoundedConnection isInverted />
                        </>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

export default MetroLineOneLine;
