import React from "react";
import ReactEcharts from "echarts-for-react";
import { GETPEOPLECOUTDATAWEEKLY } from "_queries";
import { useQuery } from "@apollo/client";


function StackedBarChartWeekly(barchart_data) {  

   // Barchart Data

   interface DayCountItem {
      outgoing?: string; // Assuming 'human' can be a string or undefined
      incoming?: string; // Assuming 'motorcycle/bicycle' can be a string or undefined
       // Define other properties if needed
     }

   const currentData = barchart_data?.human?.this_week_data ?? {};
   const averageData = barchart_data?.human?.weekly_avg_data ?? {};
   const daysOfWeek = Object.keys(currentData);
   const currentValues = Object.values(currentData);
   const averageValues = Object.values(averageData);
   
   const option = {
    color: [ '#7296F9','#D0D0D0'],
    legend: {data: ["Total passengers", "Average total passengers"],top: 20,right: '60',
    textStyle: {fontSize: 14,color: '#fff',}},
    xAxis: {
      data: daysOfWeek,
      axisLabel: {
        rotate: 45, // Adjust the rotation angle as needed
        textStyle: {
          color: '#fff', // Label text color
          fontSize: 10,
        },
      },
    },
    yAxis: {axisLabel: {textStyle: {color: '#fff',fontSize: 10},} ,},
    series: [
      
      {
        data: averageValues,name: "Total passengers",
        type: 'bar',
        stack: 'x',z:1,
        itemStyle: {
          borderRadius: 0.1,
          borderWidth: 0.1
          },label: {
            show: true,
            position: "inside", // Show labels inside the bars
            color: "#fff", // Label text color
            fontSize: 10,
          },
      },{
        data: currentValues,name: "Average total passengers",
        type: 'line',z: 2,smooth: 0.3,
        itemStyle: {
          borderRadius: 0.1,
          //borderColor: '#716A6A',
          borderWidth: 0.1
          },label: {
            show: false,
            position: "inside", // Show labels inside the bars
            color: "#fff", // Label text color
            fontSize: 10,
          },
      }
    ]
  };
  

    
  return <ReactEcharts option={option} />;

}
  export default StackedBarChartWeekly;