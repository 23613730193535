import { useQuery } from '@apollo/client';
import { GETENERGYCONSUMPTIONMONTHLY } from '_queries';
import { EscalatorEnergyConsumptionMonthlyDatatype } from '_types/queries';
import BarGraphWithAverage from 'components/Graphs/BarGraphWithAverage';
import moment from 'moment';
import React from 'react';

const EnergyConsumptionMonthlyGraph: React.FC = () => {
    const query = useQuery<EscalatorEnergyConsumptionMonthlyDatatype>(GETENERGYCONSUMPTIONMONTHLY);
    const queriedData = query.data?.listEnergyConsumedMonthy;

    const data = Object.entries(
        queriedData?.reduce((obj, data) => {
            obj[data.date_hour] = obj[data.date_hour] || {};
            obj[data.date_hour][data.equipment_id] = data.total_energy_consumed;
            return obj;
        }, {}) || {}
    ).map(([k, v]) => {
        return [new Date(k).getTime() / 1000, (v as any)['AB06'], (v as any)['AB07']];
    });

    const allValues = data
        .map((a) => a.slice(1))
        .flat()
        .filter((n) => !!n) as number[];
    const yMax = Math.ceil(Math.max(...allValues));

    return (
        <BarGraphWithAverage
            title="Escalator energy consumption this month"
            data={data}
            names={['AB06', 'AB07']}
            xAxisOption={{
                type: 'value',
                /* min:
                    new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1).getTime() /
                    1000, */
                min: moment().subtract(31, 'days').startOf('day').valueOf() / 1000,
                max: moment().add(1, 'days').startOf('day').valueOf() / 1000,
                interval: 86400, // one day in seconds
                axisLabel: {
                    formatter: (n) => moment(n * 1000).format(`DD MMM`),
                    rotate: 45,
                    interval: 2,
                    showMaxLabel: false,
                    showMinLabel: false,
                },
                // boundaryGap: [43200, 43200],
            }}
            yAxisOption={{
                min: 0,
                max: yMax,
                axisLabel: {
                    formatter: (s) => s + ' kW/h',
                },
            }}
            seriesOption={{
                label: { show: false },
            }}
            tooltipOption={{
                trigger: 'item',
                formatter: (params) =>
                    params.seriesName + '</br>' + params.data[params.seriesIndex + 1] + ' KW/h',
            }}
        />
    );
};

export default EnergyConsumptionMonthlyGraph;
