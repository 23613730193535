import React, { useEffect } from 'react';
import { useAppDispatch } from 'app/hooks';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import LiveView from './LiveView';
import StatsSidebar from 'components/StatsSidebar/StatsSidebar';
import { useRoute } from 'wouter';

const StationTwinLive: React.FC = () => {
    const [_, params] = useRoute('/stations/:stationName');
    const stationName = params?.stationName ?? '';
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(setMainSidebarFull(false));
    }, []);

    return (
        <>
            {/* <StatsSidebar stationName={stationName} /> */}
            <LiveView />
        </>
    );
};

export default StationTwinLive;
