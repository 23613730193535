import React from 'react';
import DateTimePicker from 'react-datetime-picker';
import moment from 'moment';
import './datePicker.scss';

interface DatePickerProps {
    dateValue?: Date;
    setDateValue?: (value: Date) => void;
    maxDate?: Date;
    minDate?: Date;
}

const DatePicker: React.FC<DatePickerProps> = (props) => {
    const { dateValue, setDateValue, ...rest } = props;
    const [value, setValue] = React.useState<Date>(new Date());
    const [isCalendarOpen, setIsCalendarOpen] = React.useState<boolean>(false);

    return (
        <div className="DatePicker-container">
            <DateTimePicker
                value={dateValue ?? value}
                onChange={(v: Date) => {
                    if (setDateValue) setDateValue(v);
                    else setValue(v);
                }}
                className="datetime-picker"
                isCalendarOpen={isCalendarOpen}
                onCalendarClose={() => setIsCalendarOpen(false)}
                {...rest}
            />
            <input
                className="on-top"
                type="text"
                onClick={() => setIsCalendarOpen(true)}
                value={moment((dateValue ?? value).toISOString()).format('DD/MM/YYYY')}></input>
        </div>
    );
};

export default DatePicker;
