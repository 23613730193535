import { gql } from '@apollo/client';

export const GETBRIEFINGS = gql`
    query MyQuery {
        liveGenAiPrompt {
            items {
                response
                time
            }
        }
    }
`;

export const GETPLAYBACKURL = gql`
    query MyQuery {
        getUrl {
            url
        }
    }
`;

export const GENERATEPLAYBACKFILE = gql`
    query MyQuery($endS: String, $startS: String, $userName: String, $site: String) {
        StartPlaybackGeneration(
            end_time: $endS
            start_time: $startS
            user: $userName
            site: $site
        ) {
            status
        }
    }
`;

export const GETPLAYBACKFILESTATUS = gql`
    query MyQuery($userName: String) {
        getPlaybackProgress(username: $userName) {
            current_status
            site
            username
        }
    }
`;

export const GETPLAYBACKCHUNCK = gql`
    query MyQuery($lastIndex: Int, $userName: String) {
        StreamPaybackData(last_index: $lastIndex, user: $userName) {
            body
            next_index
        }
    }
`;

export const GETSERVICEORDERVISITINFO = gql`
    query MyQuery {
        getServiceOrderVisitInfo {
            equipmentNumber
            sch_maint_date
            serviceOrderNumber
            status
            type
        }
    }
`;
