import React from 'react';
import './simulationsList.scss';
import '../simulations.scss';
import List from 'components/List/List';
import { SimulationResponse } from '_types';
import { Link, useLocation, useRoute } from 'wouter';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import { useAppSelector } from 'app/hooks';
import { selectAccessToken } from 'features/auth/AuthSlice';
import { Button, IconButton } from 'components/UIComponents/Button/Button';
import { asciiFromString } from 'components/util';
import Checkbox from 'components/UIComponents/Checkbox/Checkbox';
import { ReactComponent as DeleteIcon } from 'assets/icons/delete.svg';
import Modal from 'react-modal';
import _ from 'lodash';
import { useGetSimulationAPIQuery } from 'app/store';

Modal.setAppElement('#root');

interface ListState {
    [id: string]: {
        data: SimulationResponse;
        isSelected: boolean;
    };
}

const SimulationsList: React.FC = () => {
    const [location, setLocation] = useLocation();
    const [match, params] = useRoute('/simulations/:stationName');
    const stationName = params?.stationName ?? '';

    const { data, refetch } = useGetSimulationAPIQuery(`/${stationName.toLowerCase()}`, {
        refetchOnMountOrArgChange: true,
    });

    React.useEffect(() => {
        if (!data) return;

        const state: ListState = {};
        data.forEach(
            (d) =>
                (state[d.SimulationID] = {
                    data: d,
                    isSelected: false,
                })
        );
        setListState(state);
    }, [data]);

    const [listState, setListState] = React.useState<ListState | undefined>(undefined);

    const token = useAppSelector(selectAccessToken);
    const [isDeleting, setIsDeleting] = React.useState<boolean>(false);
    const deleteFunction = React.useCallback(
        async (simulationId: string) =>
            fetch(`${process.env.REACT_APP_SIMULATION_API_URL}/${stationName}/${simulationId}`, {
                method: 'DELETE',
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            }),
        []
    );
    const deleteSimulations = React.useCallback(
        (simulationIds: (string | undefined)[], idx = 0) => {
            if (idx === simulationIds.length) {
                refetch();
                setIsModalOpen(false);
                setIsDeleting(false);
                return;
            }
            const id = simulationIds[idx];
            if (!id) {
                deleteSimulations(simulationIds, idx + 1);
            } else {
                deleteFunction(id)
                    .then(() => {
                        deleteSimulations(simulationIds, idx + 1);
                    })
                    .catch((e) => {
                        refetch();
                        setIsModalOpen(false);
                        console.error('--ERROR--', e);
                    });
            }
        },
        []
    );

    const [isAnyChecked, setIsAnyChecked] = React.useState<boolean>(false);
    const [isAllChecked, setIsAllChecked] = React.useState<boolean>(false);

    React.useEffect(() => {
        if (!listState) return;
        setIsAnyChecked(
            Object.values(listState).reduce(
                (boolean, simulation) => boolean || simulation.isSelected,
                false
            )
        );
        setIsAllChecked(
            Object.values(listState).reduce(
                (boolean, listObject) => boolean && listObject.isSelected,
                true
            )
        );
    }, [listState]);

    const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);
    const [isConfirmedDelete, setIsConfirmedDelete] = React.useState<boolean>(false);

    if (!match) return null;
    return (
        <div id="simulation-station-view">
            <main>
                {!listState ? (
                    <LoadingPage />
                ) : (
                    <>
                        <Modal
                            isOpen={isModalOpen}
                            onRequestClose={() => setIsModalOpen(false)}
                            className="Modal"
                            overlayClassName="Overlay">
                            <div className="modal-header">Please Note!</div>
                            <div className="modal-body">
                                <div className="modal-first-line modal-text">
                                    Are you sure you want to delete your Simulation records?
                                </div>
                                <div className="modal-second-line modal-text">
                                    By deleting your records all the data will be removed from our
                                    database.
                                </div>
                                <div className="modal-third-line">
                                    <Checkbox
                                        className="security-checkbox"
                                        checked={isConfirmedDelete}
                                        onChange={() => setIsConfirmedDelete(!isConfirmedDelete)}
                                    />
                                    I acknowledge this, and want to delete the records
                                </div>
                                <div className="buttons-container">
                                    <Button
                                        disabled={!isConfirmedDelete}
                                        className="secondary"
                                        isLoading={isDeleting}
                                        onClick={() => {
                                            if (isDeleting) return;
                                            setIsDeleting(true);
                                            deleteSimulations(
                                                Object.values(listState).map((simulation) =>
                                                    simulation.isSelected
                                                        ? simulation.data.SimulationID
                                                        : undefined
                                                )
                                            );
                                        }}>
                                        Yes, Delete
                                    </Button>
                                    <Button onClick={() => setIsModalOpen(false)}>
                                        No, Lets Go Back
                                    </Button>
                                </div>
                            </div>
                        </Modal>
                        <div className="page-title">Station overview</div>
                        <List
                            head={
                                <>
                                    <tr>
                                        <td>Station name:</td>
                                        <td>{stationName}</td>
                                        <td>
                                            <div className="button-container">
                                                <Button
                                                    onClick={() =>
                                                        setLocation(stationName + '/new_simulation')
                                                    }>
                                                    New simulation
                                                </Button>
                                            </div>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <div className="delete-icon-container">
                                                <Checkbox
                                                    checked={isAllChecked}
                                                    onChange={() =>
                                                        setListState((s) => {
                                                            const copyState = _.cloneDeep(s);
                                                            for (const key in copyState) {
                                                                copyState[key].isSelected =
                                                                    isAllChecked ? false : true;
                                                            }
                                                            return copyState;
                                                        })
                                                    }
                                                />
                                                <IconButton
                                                    disabled={!isAnyChecked}
                                                    onClick={() => {
                                                        setIsModalOpen(true);
                                                    }}>
                                                    <DeleteIcon fill="currentColor" />
                                                </IconButton>
                                            </div>
                                        </td>
                                        <td>simulation name</td>
                                        <td>simulation status</td>
                                    </tr>
                                </>
                            }>
                            {Object.entries(listState)
                                .sort(
                                    (a, b) =>
                                        new Date(b[1].data.Submitted).getTime() -
                                        new Date(a[1].data.Submitted).getTime()
                                )
                                .map(([id, simulation]) => {
                                    const data = simulation.data;
                                    const name: string = data.Metadata
                                        ? JSON.parse(data.Metadata).name ?? id
                                        : id;
                                    return (
                                        <tr key={id} id={id}>
                                            <td>
                                                <Checkbox
                                                    checked={simulation.isSelected}
                                                    onChange={() =>
                                                        setListState((s) => ({
                                                            ...s,
                                                            [id]: {
                                                                data: data,
                                                                isSelected: !simulation.isSelected,
                                                            },
                                                        }))
                                                    }
                                                />
                                            </td>
                                            <td>
                                                {data.Status === 'FINISHED' ? (
                                                    <div
                                                        style={{ cursor: 'pointer' }}
                                                        onClick={() =>
                                                            setLocation(
                                                                stationName +
                                                                    '/simulator/' +
                                                                    id +
                                                                    '_' +
                                                                    JSON.parse(data.InputDefinition)
                                                                        .time.start +
                                                                    '_' +
                                                                    JSON.parse(data.InputDefinition)
                                                                        .time.end
                                                            )
                                                        }>
                                                        {name}
                                                    </div>
                                                ) : (
                                                    name
                                                )}
                                            </td>
                                            <td className={data.Status}>{data.Status}</td>
                                        </tr>
                                    );
                                })}
                        </List>
                    </>
                )}
            </main>
        </div>
    );
};

export default SimulationsList;
