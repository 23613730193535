import React from 'react';
import StationTwinLive from './StationTwinLive/StationTwinLive';
import './stationTwin.scss';
import DashBoard from 'components/DashBoards/DashBoard';
import StationTwinPlayback from './StationTwinPlayback/StationTwinPlayback';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    selectMainView,
    selectBriefingAlerts,
    setMainView,
    MainViewOptions,
} from 'features/StationView/StationViewSlice';
import { MainViewNavBar } from 'components/MainViewNavBar/MainViewNavBar';
import StatsSidebar from 'components/StatsSidebar/StatsSidebar';
import { Pills } from 'components/UIComponents/Pill/Pill';
import { useQuery } from '@apollo/client';
import { GETLATENCY } from '_queries';
import { useRoute } from 'wouter';
import VirtualOperatorView from 'components/VirtualOperatorView/VirtualOperatorView';

export const StationTwinMainView: React.FC = () => {
    const [_, params] = useRoute('/stations/:stationName');
    const stationName = params?.stationName ?? '';

    const mainView = useAppSelector(selectMainView);
    const briefingAlerts = useAppSelector(selectBriefingAlerts);
    const dispatch = useAppDispatch();
    const setView = (view) => {
        dispatch(setMainView(view));
    };

    React.useEffect(() => {
        const localStorageMainView = localStorage.getItem('mainView');
        if (localStorageMainView) dispatch(setMainView(localStorageMainView as MainViewOptions));
        else dispatch(setMainView('live'));

        return () => localStorage.setItem('mainView', 'live');
    }, []);

    return (
        <div>
            <div id="stationTwin">
                <main>
                    <MainViewNavBar>
                        <LiveDot />
                        <Pills
                            options={['live', 'dashboard', 'playback' /* , 'virtual operator' */]}
                            active={mainView}
                            eOnChange={setView}
                            alerts={briefingAlerts ? ['virtual operator'] : []}
                        />
                    </MainViewNavBar>
                    {mainView === 'dashboard' ? (
                        <DashBoard /> /* : mainView === 'virtual operator' ? (
                        <VirtualOperatorView />
                    )  */
                    ) : (
                        <>
                            <StatsSidebar stationName={stationName} />
                            <div className="stationInterface">
                                {mainView === 'live' ? (
                                    <StationTwinLive />
                                ) : (
                                    <StationTwinPlayback />
                                )}
                            </div>
                        </>
                    )}
                </main>
            </div>
        </div>
    );
};

const LiveDot: React.FC = () => {
    const view = useAppSelector(selectMainView);
    const { data, startPolling, stopPolling } = useQuery(GETLATENCY);

    const isLive = view === 'live' || view === 'virtual operator';

    React.useEffect(() => {
        if (view === 'live') {
            startPolling(500);
        } else {
            stopPolling();
        }
        return stopPolling;
    }, [view]);

    return (
        <div
            className="live-dot-container"
            style={{ paddingRight: (view === 'live' ? '1' : '0') + 'rem' }}>
            {isLive && data ? (
                <span className="latency">{Math.floor(data.getLatencyLive.latency) + ' ms'}</span>
            ) : (
                ''
            )}
            <div className={'live-dot ' + (isLive ? 'live' : 'disabled')}></div>
        </div>
    );
};
