import React from 'react';
import ReactEcharts from 'echarts-for-react';
import { EChartsCoreOption } from 'echarts';
import { TimeFormatterFromSeconds, doubleDigit } from 'components/util';
import { isAverage } from './graphUtils';

interface Props {
    title: string;
    data: (number | null)[][];
    names: string[];
    color?: string[];
    thresholds?: { color: string; value: number; label?: string }[];
    xAxisOption?: Record<string, any>;
    yAxisOption?: Record<string, any>;
    seriesOption?: Record<string, any>;
    tooltipOption?: Record<string, any>;
}

const TimeLineGraph: React.FC<Props> = ({
    title,
    data,
    names,
    color = ['#7296F9', '#4373F7', '#1450F5'],
    thresholds,
    xAxisOption,
    yAxisOption,
    seriesOption,
    tooltipOption,
}) => {
    if (!data || data[0]?.length === 0)
        return (
            <div className="chart-container">
                <div className="chart-title">
                    <p>{title}</p>
                </div>
            </div>
        );

    const dimensions = ['timestamp', ...names];

    const series = dimensions.slice(1).map((name, idx) => ({
        encode: {
            x: 'timestamp',
            y: name,
        },
        color: isAverage(name) ? '#D0D0D0' : undefined,
        name: name,
        type: 'line',
        connectNulls: true,
        smooth: 0.3,
        markLine:
            thresholds && idx === 0
                ? {
                      data: thresholds.map((threshold) => [
                          {
                              xAxis: xAxisOption?.min,
                              yAxis: threshold.value,
                              symbol: 'none',
                              lineStyle: {
                                  color: threshold.color,
                                  type: 'solid',
                              },
                              label: {
                                  show: !!threshold.label,
                                  position: 'insideEndTop',
                                  formatter: threshold.label || '',
                                  textStyle: { color: '#fff', fontSize: '1rem' },
                              },
                          },
                          {
                              xAxis: xAxisOption?.max,
                              yAxis: threshold.value,
                              symbol: 'none',
                          },
                      ]),
                      tooltip: {
                          trigger: 'item',
                          formatter: (params) => TimeFormatterFromSeconds(params.data.yAxis, true),
                      },
                  }
                : null,
        ...seriesOption,
    }));

    const textStyle = { color: '#fff', fontSize: 10 };
    const option: EChartsCoreOption = {
        color,
        legend: {
            data: dimensions.slice(1),
            top: 'top',
            left: 'right',
            textStyle: { ...textStyle, fontSize: 14 },
        },
        xAxis: {
            type: 'value',
            showMinLabel: true,
            showMaxLabel: true,
            splitLine: {
                show: false,
            },
            ...xAxisOption,
            axisLabel: {
                textStyle,
                formatter: (n) => TimeFormatterFromSeconds(n / 1000, true),
                ...xAxisOption?.axisLabel,
            },
        },
        yAxis: {
            minInterval: 1,
            ...yAxisOption,
            axisLabel: {
                textStyle,
                ...yAxisOption?.axisLabel,
            },
        },
        dataset: {
            source: data,
            dimensions: dimensions,
        },
        series: series,
        tooltip: {
            ...tooltipOption,
        },
    };
    return (
        <div className="chart-container">
            <div className="chart-title">
                <p>{title}</p>
            </div>
            <ReactEcharts option={option} />
        </div>
    );
};
export default TimeLineGraph;
