import React from 'react';
import './checkbox.scss';

const Checkbox: React.FC<React.HTMLProps<HTMLInputElement>> = (props) => {
    return (
        <div className="checkbox-container">
            <input type="checkbox" {...props} />
            {<span className="checkmark"></span>}
        </div>
    );
};

export default Checkbox;
