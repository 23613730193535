import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CognitoUser } from '@aws-amplify/auth';

import { RootState } from '../../app/store';
import { LoginParams } from '_types';
import { _AWS_Authenticate, _AWS_Signout } from 'features/auth/AuthAPI';

export interface AuthRoleKeys {
    accessKey: string;
    secretKey: string;
    sessionToken: string;
}

export interface AuthState {
    loggedIn?: boolean;
    authRoleKeys: AuthRoleKeys | null;
    status: StateStatus;
    error: string | null;
    user: CognitoUser | null;
    accessToken: string | null;
}

export enum StateStatus {
    IDLE = 'idle',
    PENDING = 'pending',
    FAILED = 'failed',
}

const initialState: AuthState = {
    loggedIn: undefined,
    authRoleKeys: null,
    status: StateStatus.IDLE,
    error: null,
    user: null,
    accessToken: null,
};

/*===============================
             Thunks
===============================*/

export const loginThunk = createAsyncThunk(
    'auth/login',
    async (params: LoginParams, { dispatch }) => {
        const user = await _AWS_Authenticate(params);
        dispatch(setUser(user));
        return true;
    }
);

export const logoutThunk = createAsyncThunk('auth/logout', async () => {
    await _AWS_Signout();
    return true;
});

/*===============================
              Slice
===============================*/

export const AuthSlice = createSlice({
    name: 'Auth',
    initialState,
    reducers: {
        setLoggedIn: (state, action: PayloadAction<boolean>) => {
            state.loggedIn = action.payload;
        },
        setUser: (state, action: PayloadAction<CognitoUser>) => {
            state.user = action.payload;
            action.payload.getSession((e, s) => (state.accessToken = s.idToken.jwtToken));
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(loginThunk.pending, (state) => {
                state.status = StateStatus.PENDING;
            })
            .addCase(loginThunk.fulfilled, (state) => {
                state.status = StateStatus.IDLE;
                state.loggedIn = true;
            })
            .addCase(loginThunk.rejected, (state) => {
                state.status = StateStatus.FAILED;
            })
            .addCase(logoutThunk.fulfilled, (state) => {
                state.status = StateStatus.IDLE;
                state.loggedIn = false;
            });
    },
});

/*===============================
            Actions
===============================*/

export const { setLoggedIn, setUser } = AuthSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectAuth = (state: RootState) => state.auth;
export const selectLoggedIn = (state: RootState) => state.auth.loggedIn;
export const selectUser = (state: RootState) => state.auth.user;
export const selectUserName = (state: RootState) => state.auth.user?.getUsername();
export const selectAccessToken = (state: RootState) => state.auth.accessToken;

export default AuthSlice.reducer;
