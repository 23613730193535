import React from "react";
import ReactEcharts from "echarts-for-react";
import { GETPEOPLECOUTDATADAILY } from "_queries";
import { useQuery } from "@apollo/client";


function StackedBarChart(barchart_data) {  

  const formattedString_avg = (barchart_data?.avg_last_3_days ?? '').replace(/=/g, ':').replace(/(\d+)=/g, '"$1":').replace(/(\w+)(?=:)/g, '"$1"');
  
  const avg_last_3_days = formattedString_avg ? JSON.parse(formattedString_avg) : {}; // Check if formattedString_avg is not undefined
  const avg_last_3_days_=aggregateDataByTwoHours(avg_last_3_days)
  const avg_last_3_days_keys = Object.keys(avg_last_3_days_);
  const incomingValues_avg = avg_last_3_days_keys.map(key => parseInt(avg_last_3_days_[key].incoming));
  const outgoingValues_avg = avg_last_3_days_keys.map(key => parseInt(avg_last_3_days_[key].outgoing));
  const total_avg = incomingValues_avg.map((num, index) => num + outgoingValues_avg[index]);
  
  const formattedString_daily = (barchart_data?.today_data ?? '').replace(/=/g, ':').replace(/(\d+)=/g, '"$1":').replace(/(\w+)(?=:)/g, '"$1"') ;
  
  const daily_data = formattedString_daily ? JSON.parse(formattedString_daily) : {}; // Check if formattedString_daily is not undefined
  const daily_data_=aggregateDataByTwoHours(daily_data)
  const daily_data_keys = Object.keys(daily_data_);
  const incomingValues = daily_data_keys.map(key => parseInt(daily_data_[key].incoming));
  const outgoingValues = daily_data_keys.map(key => parseInt(daily_data_[key].outgoing));
 
   const option = {
    color: [ '#7296F9','#D0D0D0'],
    legend: {data: ["Incoming", "Outgoing"],top: 20,right: '60',
      textStyle: {fontSize: 14,color: '#fff',}},
    
    xAxis: {
      data: avg_last_3_days_keys,axisLabel: {
        rotate: 45, // Adjust the rotation angle as needed
        textStyle: {
          color: '#fff', // Label text color
          fontSize: 10,
        },
      },
    },
    yAxis: {axisLabel: {textStyle: {color: '#fff',fontSize: 10},} ,},
    series: [
      {
        data: incomingValues,name: "Incoming",
        type: 'bar',
        stack: 'x',
        itemStyle: {
            borderRadius: 0.1,
            //borderColor: '#fff',
            borderWidth: 0.1
            },
      },
      {
        data: outgoingValues,name: "Outgoing",
        type: 'bar',
        stack: 'x',itemStyle: {
            borderRadius: 0.1,
            //borderColor: '#fff',
            borderWidth: 0.1
            },
      },
      {
        data: total_avg,name: "Average People Count",
        type: 'line',z: 2,smooth: 0.3,
        itemStyle: {
          borderRadius: 0.1,
          //borderColor: '#716A6A',
          borderWidth: 0.1
          }
      }
      
    ]
  };
  

    
  return <ReactEcharts option={option} />;

}
  export default StackedBarChart;

  function aggregateDataByTwoHours(data) {
    const aggregatedData = {};
  
    for (let hour = 5; hour < 24; hour += 2) {
      const timeLabel = hour < 12 ? 'AM' : 'PM';
      const displayHour = hour <= 12 ? hour : hour - 12;
      const intervalKey = `${displayHour}-${displayHour + 1} ${timeLabel}`;
  
      aggregatedData[intervalKey] = {
        incoming: 0,
        outgoing: 0,
      };
  
      for (let h = hour; h < hour + 2; h++) {
        if (data[h]) {
          if (data[h].incoming) {
            aggregatedData[intervalKey].incoming += data[h].incoming;
          }
          if (data[h].outgoing) {
            aggregatedData[intervalKey].outgoing += data[h].outgoing;
          }
        }
      }
    }
  
    return aggregatedData;
  }