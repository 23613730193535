import React from 'react';
import { useRoute } from 'wouter';
import FloorNavigator from 'components/FloorNavigator';
import Visualizer, { VizStore } from 'components/Visualizer/Visualizer';
import { SimulationDataManager } from 'features/DataManager/simulationDataManager';
import '../../StationTwin/stationTwin.scss';
import NewPlaybackSlider from 'components/PlaybackSlider/PlaybackSlider';
import { useAppSelector } from 'app/hooks';
import _ from 'lodash';
import { InputDataType } from '../SimulationMainView';
import * as THREE from 'three';
import { selectSimulationReached } from 'features/StationView/SimulationSlice';
import { useGetDigiTwinUIAPIQuery } from 'app/store';

interface Props {
    simulationDataManager: SimulationDataManager;
    inputData?: InputDataType;
}

const SimulationDigiTwin: React.FC<Props> = ({ simulationDataManager, inputData }) => {
    const [match, params] = useRoute('/simulations/:stationName/simulator/:simulationId');
    const stationName = params?.stationName ?? '';
    const [simulationId, startTime, endTime] = (params?.simulationId ?? '').split('_');
    const [startMs, endMs] = [startTime, endTime].map((s) => +s * 3600000);

    const simulationReached = useAppSelector(selectSimulationReached);

    const equipmentsQuery = useGetDigiTwinUIAPIQuery(
        'stations/' + stationName.toLowerCase() + '/equipments'
    );

    React.useEffect(() => {
        if (!equipmentsQuery?.data?.equipments?.elevators) return;
        simulationDataManager.SetVisualization(
            VizStore.getInstance().visualization,
            equipmentsQuery.data.equipments.elevators
        );
        simulationDataManager.setSlice(startMs);
    }, [equipmentsQuery]);

    React.useEffect(() => {
        if (!inputData) return;

        const activeColor = new THREE.Color(0x1ed273);
        const errorColor = new THREE.Color(0xffa023);

        for (const elevator in inputData.equipment.elevators) {
            if (!inputData.equipment.elevators[elevator]) {
                VizStore.getInstance().visualization.static.SetAssetsFeatures([
                    { name: elevator, color: errorColor },
                    { name: elevator + '_shaft', color: errorColor },
                ]);
            } else {
                VizStore.getInstance().visualization.static.SetAssetsFeatures([
                    { name: elevator, color: activeColor },
                    { name: elevator + '_shaft', color: activeColor },
                ]);
            }
        }
        for (const escalator in inputData.equipment.escalators) {
            if (inputData.equipment.escalators[escalator] === 'disabled') {
                VizStore.getInstance().visualization.static.SetAssetsFeatures([
                    { name: escalator, color: errorColor },
                ]);
            }
        }
    }, [inputData]);

    const onInputChange = React.useCallback(
        (input: number) => {
            if (!simulationDataManager.viz) return;
            simulationDataManager.setSlice(input);
        },
        [simulationDataManager.viz]
    );

    if (!match) return null;
    return (
        <div className="stationInterface">
            <FloorNavigator />

            <Visualizer stationName={stationName} />

            <NewPlaybackSlider
                start={startMs}
                end={endMs}
                reached={simulationReached}
                onInputChange={onInputChange}
                inputInterval={100}
                labels={{
                    start: startTime + ':00',
                    end: endTime + ':00',
                }}
            />
        </div>
    );
};

export default SimulationDigiTwin;
