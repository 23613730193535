import React from 'react';
import Toggle from 'react-toggle';
import 'react-toggle/style.css';
import './toggle.scss';

interface Props {
    checked?: boolean;
    defaultChecked?: boolean;
    onChange?: (e: any) => void;
    onFocus?: (e: any) => void;
    onBlur?: (e: any) => void;
    name?: string;
    value?: string;
    id?: string;
    'aria-labelledby'?: string;
    'aria-label'?: string;
    disabled?: boolean;
    className?: string;
}

const CustomToggle: React.FC<Props> = (props) => {
    return <Toggle icons={false} {...props}></Toggle>;
};

export default CustomToggle;
