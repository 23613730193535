import React from 'react';

import { ReactComponent as RightDownArrow } from '../../assets/icons/arrow-rightdown.svg';
import { ReactComponent as RightUpArrow } from '../../assets/icons/arrow-rightup.svg';
import { ReactComponent as AlertIcon } from '../../assets/icons/ico-warning.svg';
import { useQuery } from '@apollo/client';
import { GETJOURNEYTIME, GETPEOPLEONPLATFORM, GETTOTALCOUNTSINOUT } from '_queries';
import { secToMins } from 'app/util';
import { LiveJourneyTimeData, PeopleOnPlatformType, PeopleTotalCountData } from '_types/queries';

const LiveComponents: React.FC = () => {
    const {
        data: jtData,
        startPolling: jtStartPolling,
        stopPolling: jtStopPolling,
    } = useQuery<LiveJourneyTimeData>(GETJOURNEYTIME);
    const {
        data: tcData,
        startPolling: tcStartPolling,
        stopPolling: tcStopPolling,
    } = useQuery<PeopleTotalCountData>(GETTOTALCOUNTSINOUT);
    const {
        data: peopleOnPlatformData,
        startPolling: peopleOnPlatformStartPolling,
        stopPolling: peopleOnPlatformStopPolling,
    } = useQuery<PeopleOnPlatformType>(GETPEOPLEONPLATFORM);

    React.useEffect(() => {
        jtStartPolling(500);
        tcStartPolling(500);
        peopleOnPlatformStartPolling(2000);

        return () => {
            jtStopPolling();
            tcStopPolling();
            peopleOnPlatformStopPolling();
        };
    }, []);

    return (
        <div className="live-stats stats-group">
            <div className="station-overview stats-data">
                <label htmlFor="station-overview">Station overview</label>
                <div className="occupant-counts data-group station-overview-data-group">
                    <label htmlFor="occupant-counts">Total passengers</label>
                    <div className="station-overview-data">
                        <span className="incoming">
                            <RightDownArrow />
                            {tcData?.getTotalCountsInOut[0].incoming_people_count ?? '--'}
                        </span>
                        <span className="outgoing">
                            {tcData?.getTotalCountsInOut[0].outgoing_people_count ?? '--'}
                            <RightUpArrow />
                        </span>
                    </div>
                </div>
                <div className="bike-counts data-group  station-overview-data-group">
                    <label htmlFor="bike-counts">Bike users</label>
                    <div className="station-overview-data">
                        <span className="incoming">
                            <RightDownArrow />
                            {tcData?.getTotalCountsInOut[0].incoming_bike_count ?? '--'}
                        </span>
                        <span className="outgoing">
                            {tcData?.getTotalCountsInOut[0].outgoing_bike_count ?? '--'}
                            <RightUpArrow />
                        </span>
                    </div>
                </div>
                <div className="journey-times data-group  station-overview-data-group">
                    <label htmlFor="journey-times">Journey times</label>
                    <div className="station-overview-data">
                        <span className="incoming">
                            <RightDownArrow />
                            {secToMins(
                                jtData
                                    ? Math.round(
                                          jtData.listLiveJourneyEvents.items
                                              .filter((o) => o.traffic_type === 'incoming')
                                              .reduce((n, o) => n + o.avg_journey_time_s, 0) / 2
                                      )
                                    : undefined
                            )}
                        </span>
                        <span className="outgoing">
                            {secToMins(
                                jtData
                                    ? Math.round(
                                          jtData.listLiveJourneyEvents.items
                                              .filter((o) => o.traffic_type === 'outgoing')
                                              .reduce((n, o) => n + o.avg_journey_time_s, 0) / 2
                                      )
                                    : undefined
                            )}
                            <RightUpArrow />
                        </span>
                    </div>
                </div>
            </div>
            <div className="platform-stats stats-data">
                <label htmlFor="platform-stats">Platform stats</label>
                <div className="data-group">
                    <div className="data">
                        <label>People on platform</label>
                        <span>
                            {peopleOnPlatformData?.getPlatformCount[0].platform_count ?? '--'}
                        </span>
                    </div>
                    <div className="platform-wt">
                        <label>Waiting times</label>
                        <div>
                            <div className="data">
                                <label>To east</label>
                                <span>--</span>
                            </div>
                            <div className="data">
                                <label>To west</label>
                                <span>--</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {peopleOnPlatform > 90 && (
                    <div className="alert live">
                        <AlertIcon /> <span>Congestion on the platform</span>
                    </div>
                )} */}
            </div>
        </div>
    );
};

export default LiveComponents;
