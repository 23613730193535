import React from 'react';
import FloorNavigator from 'components/FloorNavigator';
import Visualizer, { VizStore } from 'components/Visualizer/Visualizer';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { PlaybackDataManager } from 'features/DataManager/PlaybackDataManager';
import NewPlaybackSlider from 'components/PlaybackSlider/NewPlaybackSlider';
import { useRoute } from 'wouter';
import {
    selectConfirmTimes,
    selectErrorCheckingStatus,
    selectErrorFetching,
    selectErrorGenerating,
    selectPlaybackEndMs,
    selectPlaybackReachedMs,
    selectPlaybackStartMs,
    setConfirmTimes,
    setPlaybackEndMs,
    setPlaybackReachedMs,
    setPlaybackStartMs,
} from 'features/StationView/PlaybackViewSlice';
import moment from 'moment';
import { useGetDigiTwinUIAPIQuery } from 'app/store';

const PlaybackView: React.FC<{ histogramSize?: number }> = ({ histogramSize }) => {
    const [_, params] = useRoute('/stations/:stationName');
    const stationName = params?.stationName ?? '';

    const [isLoading, setIsLoading] = React.useState<boolean>(true);

    const [startMs, endMs, reachedMs, confirmTimes] = [
        useAppSelector(selectPlaybackStartMs),
        useAppSelector(selectPlaybackEndMs),
        useAppSelector(selectPlaybackReachedMs),
        useAppSelector(selectConfirmTimes),
    ];
    const dispatch = useAppDispatch();

    const [playbackDataManager, setPlaybackDataManager] = React.useState<PlaybackDataManager>();

    React.useEffect(() => {
        const pdm = new PlaybackDataManager(stationName);
        setPlaybackDataManager(pdm);
    }, []);

    const equipmentsQuery = useGetDigiTwinUIAPIQuery(
        'stations/' + stationName.toLowerCase() + '/equipments'
    );

    React.useEffect(() => {
        if (!playbackDataManager || !equipmentsQuery?.data?.equipments?.elevators) return;
        playbackDataManager.SetVisualization(
            VizStore.getInstance().visualization,
            equipmentsQuery.data.equipments.elevators
        );

        const startTimeMs = moment().subtract(15, 'minute').valueOf();
        const endTimeMs = moment().valueOf();
        dispatch(setPlaybackStartMs(startTimeMs));
        dispatch(setPlaybackReachedMs(startTimeMs));
        dispatch(setPlaybackEndMs(endTimeMs));
        setIsLoading(true);

        playbackDataManager.StartPlaybackFileCreation(startTimeMs, endTimeMs, () => {
            setIsLoading(false);
            console.log('calling callback');
        });
    }, [playbackDataManager, equipmentsQuery]);

    React.useEffect(() => {
        if (!playbackDataManager || !confirmTimes) return;

        setIsLoading(true);

        playbackDataManager.StartPlaybackFileCreation(startMs, endMs, () => {
            setIsLoading(false);
            dispatch(setConfirmTimes(false));
        });
    }, [confirmTimes]);

    const onInputChange = (input: number) => {
        if (!playbackDataManager) return;
        playbackDataManager.setSlice(input);
    };

    const errorGenerating = useAppSelector(selectErrorGenerating);
    const errorCheckingStatus = useAppSelector(selectErrorCheckingStatus);
    const errorFetching = useAppSelector(selectErrorFetching);

    return (
        <div>
            <FloorNavigator />

            <Visualizer stationName={stationName} />

            {!errorGenerating && !errorCheckingStatus && !errorFetching && (
                <NewPlaybackSlider
                    startMs={startMs}
                    endMs={endMs}
                    reachedMs={reachedMs}
                    isLoading={isLoading}
                    onInputChange={onInputChange}
                    showLabels={true}
                />
            )}
        </div>
    );
};

export default PlaybackView;

// ---------- TBD: Add histogram to slider -------------- //

/* type HistogramProps = {
    data: number[];
};

const Histogram: React.FC<HistogramProps> = ({ data }) => {
    const width = 100 / (data.length + 1);
    return (
        <div className="histogram">
            {data.map((n, i) => (
                <div
                    className="bar"
                    key={i}
                    style={{
                        height: `${n}px`,
                        width: `${width}%`,
                    }}></div>
            ))}
        </div>
    );
};

const createHistogramData = (data: PlaybackDataType, times: Times, numberOfBar = 30): number[] => {
    const timeDiff = (times.end.getTime() - times.start.getTime()) / numberOfBar;
    const intervals = Array.from({ length: numberOfBar }, (_, idx) => ({
        start: times.start.getTime() + idx * timeDiff,
        end: times.start.getTime() + (idx + 1) * timeDiff,
    }));
    console.log(times.start.getTime(), times.end.getTime());
    const result = Array.from({ length: numberOfBar }, () => 0);
    Object.entries(data).forEach(([k, v]) => {
        const index = assignToHistogramBars(intervals, +k);
        if (!index) return;
        result[index] += v.position.length;
    });
    const histogramValues = result.map((n, i) => (i === 0 ? n : n === 0 ? result[i - 1] : n));
    console.log(histogramValues);
    return histogramValues;
};

const assignToHistogramBars = (
    array: { start: number; end: number }[],
    number: number,
    indexes?: { start: number; end: number }
) => {
    let start = 0;
    let end = array.length;
    if (indexes) {
        start = indexes.start;
        end = indexes.end;
    }
    if (start === end) {
        if (number > array[start].start && number <= array[start].end) {
            return start;
        } else {
            return null;
        }
    }

    const index = Math.floor(start + (end - start) / 2);

    if (number > array[index].start && number <= array[index].end) {
        return index;
    } else if (number <= array[index].start) {
        const newIndex = Math.floor(start + (index - start) / 2);
        if (newIndex === index) return null;
        return assignToHistogramBars(array, number, { start, end: index });
    } else if (number > array[index].end) {
        const newIndex = Math.floor(index + (end - index) / 2);
        if (newIndex === index) {
            return null;
        }
        return assignToHistogramBars(array, number, { start: index, end });
    }
}; */
