import { ObjPosition, PeopleDraw, StationParams } from '_types';
import { Viewport } from '../viewport';
import * as THREE from 'three';
import { setYAxisVisibility } from '../util';
import { bike, person } from '../geometries';

export class PeopleAssets {
    vpt: Viewport;
    maxPoints = 2000;
    dataBlob: THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>;
    scene: THREE.Group;
    defaultColor = new THREE.Color(0x65d2d1);
    enabled: boolean;
    meshes: Array<THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>>;
    axisYLimits: { bottom: number; top: number };

    //meshes store color and shape
    positions: Array<THREE.Vector3>;
    meshColors: Array<THREE.Color>;
    meshVisibility: Array<boolean>;

    hiddenAsset: boolean;

    constructor(vpt: Viewport) {
        this.vpt = vpt;

        this.axisYLimits = { bottom: -Infinity, top: Infinity };
        this.dataBlob = new THREE.Mesh();
        this.scene = new THREE.Group();
        this.scene.name = 'DynamicAssets';

        // this.scene.position.features[stationName].stationParams?.position
        this.enabled = false;

        this.hiddenAsset = false;

        this.InitBuffers();

        this.LoadModel();

        // finally, add the dynamic assets scene to the master scene
        this.vpt.scene.add(this.scene);
    }

    private LoadModel = () => {
        const personGeometry = person();
        this.dataBlob = new THREE.Mesh(personGeometry);
        // (this.dataBlob.morphTargetInfluences as Array<number>) = [1];
        this.DefineMaterial();
        this.BuildMeshes();
        this.enabled = true;
    };

    private DefineMaterial = () => {
        console.log('--- define material ---');
        const basicMaterial = new THREE.MeshBasicMaterial({
            color: new THREE.Color().copy(this.defaultColor),
        });
        this.dataBlob.material = basicMaterial;
    };

    private BuildMeshes = () => {
        console.log('--- build meshes ---');

        this.meshes = new Array<THREE.Mesh<THREE.BufferGeometry, THREE.MeshBasicMaterial>>(
            this.maxPoints
        );

        let i = 0;
        for (i = 0; i < this.maxPoints; i++) {
            const obj = this.dataBlob.clone();
            obj.name = `dataPoint_${i}`;

            obj.material = this.dataBlob.material.clone();
            obj.material.color.copy(this.defaultColor);
            obj.material.transparent = true;
            obj.material.opacity = 1;
            obj.position.copy(this.positions[i]);
            obj.matrixAutoUpdate = true;
            obj.visible = false;
            this.meshes[i] = obj;
            this.scene.add(obj);
        }
        console.log('--- end build meshes ---');
    };

    private InitBuffers() {
        this.positions = Array.from({ length: this.maxPoints }, () => new THREE.Vector3(0, 0, 0));
        this.meshColors = Array.from({ length: this.maxPoints }, () =>
            new THREE.Color().copy(this.defaultColor)
        );
        this.meshVisibility = Array.from({ length: this.maxPoints }, () => false);
    }

    public UpdateMeshes(data: PeopleDraw[]) {
        this.ClearBuffers();

        for (let ii = 0; ii < data.length; ii++) {
            const person = data[ii];
            const x = +person.position.x;
            const y = +person.position.y;
            const z = +person.position.z;
            const position = new THREE.Vector3(x, y, z);
            this.positions[ii] = position;
            this.meshColors[ii] = this.defaultColor;
            this.meshVisibility[ii] = setYAxisVisibility(
                y,
                this.axisYLimits.bottom,
                this.axisYLimits.top
            );
        }

        this.SyncBuffers();
        if (this.hiddenAsset) {
            this.SetAxisYLimits(this.axisYLimits.bottom, this.axisYLimits.top);
            this.hiddenAsset = false;
        }
    }

    private ClearBuffers() {
        let i = 0;
        for (i = 0; i < this.maxPoints; i++) {
            this.positions[i].set(0, 0, 0);
            this.meshColors[i].copy(this.defaultColor);
            this.meshVisibility[i] = false;
        }
    }

    private SyncBuffers() {
        let i = 0;
        for (i = 0; i < this.maxPoints; i++) {
            const mesh = this.meshes[i];
            const position = this.positions[i];
            mesh.material.opacity = 1;
            if (this.isStatue(position)) mesh.material.opacity = 0;
            mesh.position.copy(position);
            mesh.material.color = this.meshColors[i];
            mesh.visible = this.meshVisibility[i];
            // (mesh.morphTargetInfluences as Array<number>)[2] = 1;
        }
    }

    public SetAxisYLimits(bottom?: number, top?: number) {
        this.axisYLimits.bottom = bottom ?? -Infinity;
        this.axisYLimits.top = top ?? Infinity;

        if (!this.hiddenAsset) {
            this.meshes.forEach((mesh, idx) => {
                if (mesh.position.x === 0 && mesh.position.y === 0 && mesh.position.z === 0) return;
                this.meshVisibility[idx] = setYAxisVisibility(
                    mesh.position.y,
                    this.axisYLimits.bottom,
                    this.axisYLimits.top
                );
            });
        }

        this.SyncBuffers();
    }

    public HidePeople() {
        this.hiddenAsset = true;
        this.meshVisibility = this.meshVisibility.map((_) => false);
        this.SyncBuffers();
    }

    private CreateBikeModel() {
        const mergedGeometry = bike();
        const basicMaterial = new THREE.MeshBasicMaterial({
            color: new THREE.Color(0xffff00),
        });

        const mesh = new THREE.Mesh(mergedGeometry, basicMaterial);

        mesh.position.set(190, -20.75, -149);

        this.scene.add(mesh);
    }

    private isStatue(position: ObjPosition) {
        return (
            (position.x > 175.37 &&
                position.x < 176.2 &&
                position.z > -163.0893 &&
                position.z < -162.222 &&
                position.y < -20) ||
            (position.x > 176 &&
                position.x < 176.5 &&
                position.z > -160.05 &&
                position.z < -159.55 &&
                position.y < -20) ||
            (position.x > 176.25 &&
                position.x < 176.75 &&
                position.z > -156.35 &&
                position.z < -155.85 &&
                position.y < -20)
        );
    }
}
