import { useAppDispatch, useAppSelector } from 'app/hooks';
import {
    selectCameraViews,
    selectStationView,
    setCurrentCameraView,
} from 'features/StationView/StationViewSlice';
import React from 'react';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';

import './floorNavigator.scss';

const FloorNavigator: React.FC = () => {
    const [isElevatorsOpen, setIsElevatorsOpen] = React.useState<boolean>(false);
    const [isEscalatorsOpen, setIsEscalatorsOpen] = React.useState<boolean>(false);
    const stationViewState = useAppSelector(selectStationView);
    const activeView = stationViewState.currentCameraView;

    const dispatch = useAppDispatch();

    const cameraViews = useAppSelector(selectCameraViews);

    const floors = Array.from(cameraViews?.entries() ?? []).map(([k, v]) => {
        if (!v.equipment)
            return {
                key: k,
                label: v.label,
            };
    });
    const escalatorViews = Array.from(cameraViews?.entries() ?? []).map(([k, v]) => {
        if (v.equipment === 'escalator')
            return {
                key: k,
                label: v.label,
            };
    });
    const elevatorViews = Array.from(cameraViews?.entries() ?? []).map(([k, v]) => {
        if (v.equipment === 'elevator')
            return {
                key: k,
                label: v.label,
            };
    });

    const renderFloors = () => {
        return floors.map((flr) => {
            if (!flr) return null;
            return (
                <li
                    key={flr.key}
                    onClick={() => {
                        setIsElevatorsOpen(false);
                        setIsEscalatorsOpen(false);
                        dispatch(setCurrentCameraView(flr));
                    }}
                    className={flr.key === activeView.key ? 'active' : ''}>
                    {flr.label}
                </li>
            );
        });
    };

    const renderElevatorViews = () => {
        if (elevatorViews.reduce((b, view) => b && !view, true)) return null;
        return (
            <>
                <li
                    className={
                        'equipments' +
                        (!isElevatorsOpen && activeView.key.includes('elevator') ? ' active' : '')
                    }
                    onClick={() => {
                        setIsEscalatorsOpen(false);
                        setIsElevatorsOpen(!isElevatorsOpen);
                    }}>
                    <span>Elevators</span>
                    <ChevronDown className={isElevatorsOpen ? 'rotate' : ''} />
                </li>
                {elevatorViews.map((view) => {
                    if (!view) return null;
                    return (
                        <li
                            id={view.key}
                            key={view.key}
                            onClick={() => {
                                dispatch(setCurrentCameraView(view));
                            }}
                            className={
                                (isElevatorsOpen ? 'visible' : 'not-visible') +
                                (view.key === activeView.key ? ' active' : '')
                            }>
                            {view.label}
                        </li>
                    );
                })}
            </>
        );
    };

    const renderEscalatorViews = () => {
        if (escalatorViews.reduce((b, view) => b && !view, true)) return null;
        return (
            <>
                <li
                    className={
                        'equipments' +
                        (!isEscalatorsOpen && activeView.key.includes('escalator') ? ' active' : '')
                    }
                    onClick={() => {
                        setIsElevatorsOpen(false);
                        setIsEscalatorsOpen(!isEscalatorsOpen);
                    }}>
                    <span>Escalators</span>
                    <ChevronDown className={isEscalatorsOpen ? 'rotate' : ''} />
                </li>
                {escalatorViews.map((view) => {
                    if (!view) return null;
                    return (
                        <li
                            id={view.key}
                            key={view.key}
                            onClick={() => {
                                dispatch(setCurrentCameraView(view));
                            }}
                            className={
                                (isEscalatorsOpen ? 'visible' : 'not-visible') +
                                (view.key === activeView.key ? ' active' : '')
                            }>
                            {view.label}
                        </li>
                    );
                })}
            </>
        );
    };

    return (
        <nav id="floorNavigator">
            <ul>
                {renderFloors()}
                {renderElevatorViews()}
                {renderEscalatorViews()}
            </ul>
        </nav>
    );
};

export default FloorNavigator;
