import React from 'react';
import TimeLineGraph from '../../Graphs/TimeLineGraph';
import { OperationVariables, QueryResult } from '@apollo/client';
import {
    RDSJourneyTimes,
    SimulationJourneyTimeQueryTipe,
    SimulationJourneyTimes,
} from '_types/queries';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import { MetaDatatype } from '../SimulationMainView';
import './SimulationDashBoard.scss';
import { TimeFormatterFromSeconds } from 'components/util';

interface Props {
    queryResult: QueryResult<SimulationJourneyTimeQueryTipe, OperationVariables>;
    times: number[];
    metaData?: MetaDatatype;
}

const SimulationDashBoard: React.FC<Props> = ({
    queryResult,
    times: [startMs, endMs],
    metaData,
}) => {
    if (queryResult.loading) return <LoadingPage />;

    const color = ['#7296F9', '#e37005'];

    const simulationJourneyTimes = queryResult?.data?.listSimJourneyTimesEvents;

    const realJourneyTimes = queryResult.data?.getJourneyTimeEventsRDS;

    const jtIN = getJourneyTimesData('incoming', simulationJourneyTimes, realJourneyTimes);
    const jtOUT = getJourneyTimesData('outgoing', simulationJourneyTimes, realJourneyTimes);

    const allData = jtIN
        .concat(jtOUT)
        .map((a) => a.slice(1))
        .flat()
        .filter((n) => !!n) as number[];
    const [yMin, yMax] = [
        Math.round(Math.min(...allData) * 0.9),
        Math.round(Math.max(...allData) * 1.1),
    ];

    const waitingTimes = queryResult?.data?.listSimAverageWaitingTimes;

    const AE03wt = waitingTimes?.map((o) => [
        +o.time_ms,
        +(o.items.find((item) => item.equipment_name === 'AE03')?.AWT_s as string),
    ]) || [[]];
    const AE01_AE02wt = waitingTimes?.map((o) => [
        +o.time_ms,
        +(o.items.find((item) => item.equipment_name === 'AE01_AE02')?.AWT_s as string),
    ]) || [[]];
    const AF04wt = waitingTimes?.map((o) => [
        +o.time_ms,
        +(o.items.find((item) => item.equipment_name === 'AF04')?.AWT_s as string),
    ]) || [[]];

    const waitingTimesThresholds = [
        {
            value: 25,
            color: '#00ff00',
            label: 'excelent',
        },
        {
            value: 30,
            color: '#bdfa07',
            label: 'good',
        },
        {
            value: 40,
            color: '#fafa07',
            label: 'satisfactory',
        },
    ];
    const maxTreshold = 40;

    return (
        <div className="dashboard-container">
            {/* <div className="row">
                <SelectBox />
            </div> */}
            <div className="row">
                <TimeLineGraph
                    title="Journey time IN"
                    data={jtIN}
                    names={[metaData?.name || 'sim1', 'real']}
                    xAxisOption={{
                        min: startMs,
                        max: endMs,
                        interval: 15 * 60000,
                        axisLabel: {
                            formatter: (value) => TimeFormatterFromSeconds(value / 1000),
                        },
                    }}
                    color={color}
                    yAxisOption={{
                        min: yMin,
                        max: yMax,
                        axisLabel: {
                            formatter: (value) => TimeFormatterFromSeconds(value, true),
                        },
                    }}
                    tooltipOption={{
                        trigger: 'item',
                        formatter: (params) =>
                            TimeFormatterFromSeconds(params.data[params.seriesIndex + 1], true) +
                            '</br>' +
                            TimeFormatterFromSeconds(params.data[0] / 1000, true),
                    }}
                />
                <TimeLineGraph
                    title="Journey time OUT"
                    data={jtOUT}
                    names={[metaData?.name || 'sim1', 'real']}
                    xAxisOption={{
                        min: startMs,
                        max: endMs,
                        axisLabel: {
                            formatter: (value) => TimeFormatterFromSeconds(value / 1000),
                        },
                    }}
                    color={color}
                    yAxisOption={{
                        min: yMin,
                        max: yMax,
                        axisLabel: {
                            formatter: (value) => TimeFormatterFromSeconds(value, true),
                        },
                    }}
                    tooltipOption={{
                        trigger: 'item',
                        formatter: (params) =>
                            TimeFormatterFromSeconds(params.data[params.seriesIndex + 1], true) +
                            '</br>' +
                            TimeFormatterFromSeconds(params.data[0] / 1000, true),
                    }}
                />
            </div>
            <div className="row">
                <TimeLineGraph
                    title="Elevator waiting time - AE03"
                    data={AE03wt}
                    names={[metaData?.name || 'sim1']}
                    xAxisOption={{
                        min: startMs,
                        max: endMs,
                        axisLabel: {
                            formatter: (value) => TimeFormatterFromSeconds(value / 1000),
                        },
                    }}
                    color={color}
                    thresholds={waitingTimesThresholds}
                    yAxisOption={{
                        min: 0,
                        max: maxTreshold * 1.2,
                        interval: 10,
                        axisLabel: {
                            formatter: (value) => TimeFormatterFromSeconds(value, true),
                        },
                    }}
                    tooltipOption={{
                        trigger: 'item',
                        formatter: (params) =>
                            TimeFormatterFromSeconds(params.data[params.seriesIndex + 1], true) +
                            '</br>' +
                            TimeFormatterFromSeconds(params.data[0] / 1000, true),
                    }}
                />
                <TimeLineGraph
                    title="Elevator waiting time - AE01_AE02"
                    data={AE01_AE02wt}
                    names={[metaData?.name || 'sim1']}
                    xAxisOption={{
                        min: startMs,
                        max: endMs,
                        axisLabel: {
                            formatter: (value) => TimeFormatterFromSeconds(value / 1000),
                        },
                    }}
                    color={color}
                    thresholds={waitingTimesThresholds}
                    yAxisOption={{
                        min: 0,
                        max: maxTreshold * 1.2,
                        interval: 10,
                        axisLabel: {
                            formatter: (value) => TimeFormatterFromSeconds(value, true),
                        },
                    }}
                    tooltipOption={{
                        trigger: 'item',
                        formatter: (params) =>
                            TimeFormatterFromSeconds(params.data[params.seriesIndex + 1], true) +
                            '</br>' +
                            TimeFormatterFromSeconds(params.data[0] / 1000, true),
                    }}
                />
            </div>
        </div>
    );
};
export default SimulationDashBoard;

const getJourneyTimesData = (
    direction: 'incoming' | 'outgoing',
    simulationJourneyTimes?: SimulationJourneyTimes,
    realJourneyTimes?: RDSJourneyTimes
): (number | null)[][] => {
    const data: { [timestamp: string]: (number | null)[] } = {};
    if (!simulationJourneyTimes && !realJourneyTimes) return [[]];

    simulationJourneyTimes?.forEach((o) => {
        data[o.time_ms] = [
            +(o.items.find((a) => a.traffic_type === direction)?.avg_journey_time_s as string),
            null,
        ];
    });
    realJourneyTimes?.forEach((o) => {
        if (data[o.date]) {
            data[o.date][1] =
                direction === 'incoming'
                    ? +o.avg_incoming_journey_time
                    : +o.avg_outgoing_journey_time;
        } else {
            data[o.date] = [
                null,
                direction === 'incoming'
                    ? +o.avg_incoming_journey_time
                    : +o.avg_outgoing_journey_time,
            ];
        }
    });

    return Object.entries(data).map(([k, v]) => [+k, ...v]);
};
