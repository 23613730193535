import React from 'react';
import { ReactComponent as OfficeIcon } from '../../assets/icons/office-building-2.svg';
import { useAppSelector } from 'app/hooks';
import { selectCurrentCameraView } from 'features/StationView/StationViewSlice';
import './sidebar.scss';

type TitleProps = {
    stationName: string;
};
type ComponentProps = React.PropsWithChildren & {
    label?: string;
    containerProps?: React.HTMLProps<HTMLDivElement>;
};
type StatsSidebarProps = React.PropsWithChildren & TitleProps;

const SideBar: React.FC<StatsSidebarProps> = (props: StatsSidebarProps) => {
    const { children, ...rest } = props;

    return (
        <div id="sidebar">
            <SidebarTitle {...rest} />
            <div className="sidebar-stats" id="sidebar-stats">
                {children}
            </div>
        </div>
    );
};

export default SideBar;

const SidebarTitle: React.FC<TitleProps> = (props: TitleProps) => {
    const { stationName } = props;

    const activeView = useAppSelector(selectCurrentCameraView);

    return (
        <>
            <div className="sidebar-title">
                <h2>
                    <OfficeIcon />
                    {stationName}
                </h2>
                <h3>{activeView.label}</h3>
            </div>
        </>
    );
};

export const Sidebarcomponent: React.FC<ComponentProps> = (props) => {
    const { children, label, containerProps } = props;
    return (
        <>
            {label && <label className="stats-label">{label}</label>}
            <div className="stats-container" {...containerProps}>
                {children}
            </div>
        </>
    );
};
