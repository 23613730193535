import React from 'react';
import './numericInput.scss';

interface Props extends React.HTMLProps<HTMLInputElement> {
    value: number;
    setValue: (n: number) => void;
    label?: string;
    after?: string;
    active?: boolean;
}

const NumericInput: React.FC<Props> = ({
    value,
    setValue,
    label,
    after,
    active = true,
    ...rest
}) => {
    return (
        <div>
            {label && <span className="label">{label}</span>}
            <div className="numeric-input-container">
                <div className="numeric-input">
                    {active && <div onClick={() => setValue(value + 1)}> + </div>}
                    <input
                        {...rest}
                        type="number"
                        value={value}
                        onChange={(e) => setValue(+e.target.value)}
                        min={'0'}
                        disabled={!active}
                    />
                    {active && (
                        <div
                            onClick={() => {
                                if (value > 0) setValue(value - 1);
                            }}>
                            {' '}
                            -{' '}
                        </div>
                    )}
                </div>
                {after && <span className="after">{after}</span>}
            </div>
        </div>
    );
};

export default NumericInput;
