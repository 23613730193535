import React from 'react';
import ReactEcharts from 'echarts-for-react';

function WagonChartEast(data, xMax?: number) {
    const emetro_values = Object?.values(data) ?? {};

    const option = {
        color: [
            '#e06325',
            '#e06325',
            '#e06325',
            '#4b78f5',
            '#7296F9',
            '#A1B9FB',
            '#D0DCFD',
            '#F3EEE6',
            ' #FFE141',
            ' #D2F5FF',
            '#FFCDD7',
            ' #AAE1C8',
        ],

        dataset: {
            source: [
                ['product', 'Door 1', 'Door 2', 'Door 3'],
                ['Car A', emetro_values[0], emetro_values[1], emetro_values[2]],
                ['Car B', emetro_values[3], emetro_values[4], emetro_values[5]],
                ['Car C', emetro_values[6], emetro_values[7], emetro_values[8]],
                ['Car D', emetro_values[9], emetro_values[10], emetro_values[11]],
            ],
        },
        label: { show: false, color: '#fff' },
        itemStyle: {
            borderRadius: 0.5,
            borderColor: '#fff',
            borderWidth: 0.5,
        },
        xAxis: {
            max: xMax,
            axisLabel: {
                textStyle: {
                    color: '#fff', // Label text color
                    fontSize: 10,
                },
            },
        }, //inverse:'true'
        yAxis: [
            {
                type: 'category',
                position: 'left',
                axisLabel: {
                    textStyle: {
                        color: '#fff', // Label text color
                        fontSize: 10,
                    },
                },
            },
        ],
        // Declare several bar series, each will be mapped
        // to a column of dataset.source by default.
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
    };
    return <ReactEcharts option={option} style={{ height: '45rem' }} />;
}
export default WagonChartEast;
