import { getHours, getMinutes } from 'components/UIComponents/TimePicker/TimePicker';
import { ElevatorsSectionState } from './ElevatorSection';
import { EscalatorsSectionState } from './EscalatorSection';
import { FirstSectionState } from './FirstSection';
import { TrainSectionState } from './TrainSection';
import { UsersSectionState } from './UsersSection';
import _ from 'lodash';

export const createUserSectionState = () => {
    return {
        to_metro: {
            total: 5400,
            entries: { east: 50, west: 50 },
            passenger_groups: {
                normal_users: 93,
                bike_users: 2,
                stroller_users: 1,
                wheelchair_users: 1,
                elevators_only: 3,
            },
        },
        from_metro: {
            total: 5400,
            entries: { east: 50, west: 50 },
            passenger_groups: {
                normal_users: 93,
                bike_users: 2,
                stroller_users: 1,
                wheelchair_users: 1,
                elevators_only: 3,
            },
        },
    };
};

const getTime = (firstSectionState: FirstSectionState) => {
    return {
        start: firstSectionState.timeOfDay?.slice(0, 2) ?? '12',
        end: firstSectionState.timeOfDay?.slice(3, 5) ?? '15',
        weekday: firstSectionState.dayOfWeek,
    };
};
const getUsers = (usersSectionState: UsersSectionState, schema: Record<string, any>) => {
    const result = _.cloneDeep(usersSectionState);
    const iterate = (schemaObj, path = '') => {
        if (schemaObj.type === 'number' && schemaObj.maximum === 100) {
            const splitPath = path.split('/').slice(1);
            _.set(result, splitPath, _.get(result, splitPath) / 100);
        } else if (schemaObj.type === 'object') {
            Object.entries(schemaObj.properties).forEach(([k, v]) => iterate(v, path + '/' + k));
        }
    };
    iterate(schema);
    return result;
};

export const getAPIInput = (
    stationName: string,
    firstSectionState: FirstSectionState,
    usersSectionState: UsersSectionState,
    elevatorsSectionState: ElevatorsSectionState,
    escalatorsSectionState: EscalatorsSectionState,
    trainSectionState: TrainSectionState,
    simulationName: string,
    schema: Record<string, any>
) => {
    return {
        inputDefinition: {
            station: stationName,
            time: getTime(firstSectionState),
            users: getUsers(usersSectionState, schema?.properties.users),
            equipment: {
                elevators: elevatorsSectionState,
                escalators: escalatorsSectionState,
            },
            schedules: trainSectionState,
        },
        metadata: {
            name: simulationName,
            dayOfWeek: firstSectionState.dayOfWeek,
        },
    };
};
