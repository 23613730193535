import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import moment from 'moment';

export interface StationViewState {
    startMs: number;
    endMs: number;
    reachedMs: number;

    confirmTimes: boolean;

    errorGenerating: boolean;
    errorCheckingStatus: boolean;
    errorFetching: boolean;
}

const start = moment().subtract(15, 'minute').valueOf();

const initialState: StationViewState = {
    startMs: start,
    endMs: moment().valueOf(),
    reachedMs: start,

    confirmTimes: false,

    errorGenerating: false,
    errorCheckingStatus: false,
    errorFetching: false,
};

/*===============================
             Thunks
===============================*/

/*===============================
              Slice
===============================*/

export const PlaybackViewSlice = createSlice({
    name: 'playbackView',
    initialState,
    reducers: {
        setPlaybackStartMs: (state, action: PayloadAction<number>) => {
            state.startMs = action.payload;
        },
        setPlaybackEndMs: (state, action: PayloadAction<number>) => {
            state.endMs = action.payload;
        },
        setPlaybackReachedMs: (state, action: PayloadAction<number>) => {
            state.reachedMs = action.payload;
        },
        setConfirmTimes: (state, action: PayloadAction<boolean>) => {
            state.confirmTimes = action.payload;
        },
        setErrorGenerating: (state, action: PayloadAction<boolean>) => {
            state.errorGenerating = action.payload;
        },
        setErrorCheckingStatus: (state, action: PayloadAction<boolean>) => {
            state.errorCheckingStatus = action.payload;
        },
        setErrorFetching: (state, action: PayloadAction<boolean>) => {
            state.errorFetching = action.payload;
        },
    },
});

/*===============================
            Actions
===============================*/

export const {
    setPlaybackStartMs,
    setPlaybackEndMs,
    setPlaybackReachedMs,
    setConfirmTimes,
    setErrorGenerating,
    setErrorCheckingStatus,
    setErrorFetching,
} = PlaybackViewSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectPlaybackStartMs = (state: RootState) => state.playbackView.startMs;
export const selectPlaybackEndMs = (state: RootState) => state.playbackView.endMs;
export const selectPlaybackReachedMs = (state: RootState) => state.playbackView.reachedMs;
export const selectConfirmTimes = (state: RootState) => state.playbackView.confirmTimes;
export const selectErrorGenerating = (state: RootState) => state.playbackView.errorGenerating;
export const selectErrorCheckingStatus = (state: RootState) =>
    state.playbackView.errorCheckingStatus;
export const selectErrorFetching = (state: RootState) => state.playbackView.errorFetching;

export default PlaybackViewSlice.reducer;
