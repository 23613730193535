import React from 'react';
import ReactEcharts from 'echarts-for-react';

function WagonChartWest(data, xMax?: number) {
    const wmetro_values: string[] = Object?.values(data) ?? {};

    const option = {
        color: [
            '#e06325',
            '#e06325',
            '#e06325',
            '#7296F9',
            '#A1B9FB',
            '#D0DCFD',
            '#F3EEE6',
            ' #FFE141',
            ' #D2F5FF',
            '#FFCDD7',
            ' #AAE1C8',
        ],
        dataset: {
            source: [
                ['product', 'Door 1', 'Door 2', 'Door 3'],
                ['Car A', wmetro_values[0], wmetro_values[1], wmetro_values[2]],
                ['Car B', wmetro_values[3], wmetro_values[4], wmetro_values[5]],
                ['Car C', wmetro_values[6], wmetro_values[7], wmetro_values[8]],
                ['Car D', wmetro_values[9], wmetro_values[10], wmetro_values[11]],
            ],
        },
        label: { show: false, color: '#fff' },
        xAxis: {
            inverse: true,
            max: xMax,
            axisLabel: {
                textStyle: {
                    color: '#fff', // Label text color
                    fontSize: 10,
                },
            },
        },
        yAxis: [
            {
                type: 'category',
                position: 'right',
                color: '#fff',
                axisLabel: {
                    textStyle: {
                        color: '#fff', // Label text color
                        fontSize: 10,
                    },
                },
            },
        ],
        series: [{ type: 'bar' }, { type: 'bar' }, { type: 'bar' }],
    };

    return <ReactEcharts option={option} style={{ height: '45rem' }} />;
}

export default WagonChartWest;
