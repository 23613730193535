import React from 'react';
import './loadingPage.scss';
import { TbLoader } from 'react-icons/tb';
import Spinner from './Spinner';

type Props = {
    height?: string;
};

const LoadingPage: React.FC<Props> = (props) => {
    const { height = '100vh' } = props;

    return (
        <div className="loading" style={{ height }}>
            <Spinner />
            <p>Loading...</p>
        </div>
    );
};

export default LoadingPage;
