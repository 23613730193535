import React from 'react';
import ReactSelect, { Props, components, DropdownIndicatorProps, ControlProps } from 'react-select';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';

const DropdownIndicator = (props: DropdownIndicatorProps) => {
    return (
        <components.DropdownIndicator {...props}>
            <ChevronDown
                style={{
                    transform: props.selectProps.menuIsOpen ? 'rotate(180deg)' : '',
                }}
            />
        </components.DropdownIndicator>
    );
};

const Select: React.FC<Props> = (props) => {
    return (
        <ReactSelect
            {...props}
            styles={{
                control: (s) => ({
                    ...s,
                    backgroundColor: 'var(--dark-background-secondary)',
                    border: '1px solid white',
                    boxShadow: '0',
                    '&:hover': {
                        border: '1px solid white',
                    },
                }),
                container: (s) => ({
                    ...s,
                    padding: 0,
                }),
                menu: (s) => ({
                    ...s,
                    backgroundColor: 'var(--dark-background-secondary)',
                    border: '1px solid white',
                }),
                option: (s) => ({
                    ...s,
                    backgroundColor: 'var(--dark-background-secondary)',
                }),
                singleValue: (s) => ({
                    ...s,
                    color: 'white',
                }),
            }}
            components={{ IndicatorSeparator: null, DropdownIndicator }}
        />
    );
};

export default Select;
