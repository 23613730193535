import CustomToggle from 'components/UIComponents/Toggle/Toggle';
import React from 'react';

export type ElevatorsTypes = { [k: string]: boolean };
export type ElevatorsSectionState = ElevatorsTypes;
type Props = {
    state: ElevatorsSectionState;
    setState: React.Dispatch<React.SetStateAction<ElevatorsSectionState | undefined>>;
    stationName: string;
};

const ElevatorSection: React.FC<Props> = ({ state, setState, stationName }) => {
    return (
        <div className="equipments-details elevators">
            <div className="equipment-title">Elevators</div>
            <div className="elevators-section">
                {Object.keys(state).map((elevatorName) => {
                    if (elevatorName === 'isParametersOk') return null;
                    return (
                        <ElevatorDetail
                            key={elevatorName}
                            label={elevatorName}
                            value={state[elevatorName]}
                            onChange={(value) => setState((s) => ({ ...s, [elevatorName]: value }))}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default ElevatorSection;

type ElevatorDetailType = {
    label: string;
    value: boolean;
    onChange: (value: boolean) => void;
};

const ElevatorDetail: React.FC<ElevatorDetailType> = ({ label, onChange, value }) => {
    return (
        <div className="elevator-detail">
            <div className="elevator-detail-title">{label}</div>
            <div className="elevator">
                <span className={value ? 'span-off' : ''}>off</span>
                <CustomToggle
                    checked={value}
                    onChange={() => onChange(!value)}
                    className={'toggle-with-margin'}
                />
                <span className={!value ? 'span-off' : ''}>on</span>
            </div>
        </div>
    );
};
