import * as THREE from 'three';
import { TypeOfFeatures } from '_types';

const urheilupuistoCameraViews = new Map<
    string,
    { label: string; camera: { eye: THREE.Vector3; target: THREE.Vector3 } }
>();

urheilupuistoCameraViews.set('entrance', {
    label: 'Entrance',
    camera: {
        eye: new THREE.Vector3(31445.36129804494, 11486.114304566963, -884.6350608831708),
        target: new THREE.Vector3(128959.11933114805, -3911.8257079931636, -46156.34358459645),
    },
});
urheilupuistoCameraViews.set('overview', {
    label: 'Overview',
    camera: {
        eye: new THREE.Vector3(126866.34004237065, 40866.57593291464, 40997.570438374794),
        target: new THREE.Vector3(126714.7253950205, -7006.382109173948, -22893.00163308253),
    },
});
urheilupuistoCameraViews.set('platform', {
    label: 'Platform',
    camera: {
        eye: new THREE.Vector3(113341.18609656347, -4222.647974671028, 17450.805812503237),
        target: new THREE.Vector3(138564.63167289508, -12718.928217559835, -19342.464637022054),
    },
});
urheilupuistoCameraViews.set('escalators_a', {
    label: 'Escalator-Plat',
    camera: {
        eye: new THREE.Vector3(128994.39794850316, -14639.214684366189, 3637.9529663584563),
        target: new THREE.Vector3(100656.89130646676, -14768.743238999705, -14601.481294839998),
    },
});
urheilupuistoCameraViews.set('escalators_b', {
    label: 'Escalator-Street',
    camera: {
        eye: new THREE.Vector3(46189.31195196259, 18277.84614348818, -12412.98002587876),
        target: new THREE.Vector3(77328.93636282797, -5760.345038179308, -9869.051300080077),
    },
});
urheilupuistoCameraViews.set('elv_east', {
    label: 'East Elevators',
    camera: {
        eye: new THREE.Vector3(98013.6880712077, -10768.394293153931, -25395.942607087352),
        target: new THREE.Vector3(90566.93430436889, -11560.445548344747, -21457.64719787904),
    },
});
urheilupuistoCameraViews.set('elv_west', {
    label: 'West Elevators',
    camera: {
        eye: new THREE.Vector3(181348, -13364, 1383),
        target: new THREE.Vector3(213905, -14383, -28027),
    },
});

const urheilupuistoParams = {
    position: { x: 0, y: -16900, z: 5400 },
    rotate: {
        axis: new THREE.Vector3(1, 0, 0),
        angle: (-90 * Math.PI) / 180,
    },
    hiddenAssets: [
        'additional_floors',
        'escalator_single',
        'escalator_walls',
        'escalator_walls',
        'big_platform_lights',
        'end_walls',
        'escape_routes',
        'fire_escape_stairwell',
        'landscaping',
        'lighting',
        'main_level_glass',
        'main_level_stainless',
        'main_level_steel_elements',
        'masses',
        'P1',
        'P2',
        'P3',
        'platform_benches_screens',
        'platform_ceiling',
        'platform_ceiling_decor',
        'platform_info_screens',
        'platform_level_glass',
        'platform_level_stainless_elements',
        'platform_metro_doors',
        'platform_side_walls',
        'platform_side_walls_decor',
        'rails',
        'ramp_parking',
        'signage_in_out_elevator',
        'stairwells',
        'stickers',
        'train_car_tunnels',
        'traincars',
        'ElementType_URP_3d_xrefs_as_blocksdwg_<348719_URP_3d_xrefs',
        'tp_level_1',
        'tp_level_2',
        'tp_level_3',
        'tp_level_3_5',
        'tp_level_4',
        'tp_level_4_5',
        'tp_level_5',
    ],
};

const urheilupuistoUrls = ['/content/urheilupuisto/FullStation.glb'];

const urheilupuistoFeatures: TypeOfFeatures = {
    views: urheilupuistoCameraViews,
    urls: urheilupuistoUrls,
    stationParams: urheilupuistoParams,
};

export default urheilupuistoFeatures;
