import React from 'react';
import './loadingPage.scss';

type Props = {
    size?: number;
};

const Spinner: React.FC<Props> = ({ size }) => {
    return (
        <div
            className="spinner"
            style={size ? { width: size + 'px', height: size + 'px' } : undefined}></div>
    );
};

export default Spinner;
