import React from 'react';
import './newSimulationView.scss';
import { getAPIInput } from './util';
import _ from 'lodash';
import Collapsible from 'react-collapsible';
import { useAppSelector } from 'app/hooks';
import { selectAccessToken } from 'features/auth/AuthSlice';
import { useLocation, useRoute } from 'wouter';
import Divider from 'components/UIComponents/Divider/Divider';
import { ReactComponent as ChevronDown } from 'assets/icons/chevron-down.svg';
import FirstSection, { FirstSectionState, timeOfDay } from './FirstSection';
import UsersSection, { UsersSectionState } from './UsersSection';
import ElevatorSection, { ElevatorsSectionState } from './ElevatorSection';
import EscalatorSection, { EscalatorsSectionState } from './EscalatorSection';
import TrainSection, { TrainSectionState } from './TrainSection';
import LastSection from './LastSection';
import { useQuery } from '@apollo/client';
import { GETSIMULATIONPREPOPULATEDATA } from '_queries';
import LoadingPage from 'components/LoadingPage/LoadingPage';
import { store, useGetSimulationAPIQuery } from 'app/store';
import Ajv from 'ajv';
import { apolloClient } from 'app/apolloClient';

const NewSimulationView: React.FC = () => {
    const [location, setLocation] = useLocation();
    const [match, params] = useRoute('/simulations/:stationName/new_simulation');
    const stationName = params?.stationName ?? '';
    const ajv = new Ajv();

    const { data: schema } = useGetSimulationAPIQuery(`/${stationName.toLowerCase()}/schema`);

    // FIRST SECTION
    const [firstSectionState, setFirstSectionState] = React.useState<FirstSectionState>();

    // USERS SECTION
    const { data: firstUserSectionState, loading: userSectionLoading } = useQuery(
        GETSIMULATIONPREPOPULATEDATA
    );
    const [usersSectionState, setUsersSectionState] = React.useState<UsersSectionState>();
    const [isFetchingUsers, setIsFetchingUsers] = React.useState<boolean>(false);
    React.useEffect(() => {
        if (firstSectionState) {
            setIsFetchingUsers(true);
            apolloClient
                .query({
                    query: GETSIMULATIONPREPOPULATEDATA,
                    variables: {
                        stationname: stationName,
                        weekday: firstSectionState.dayOfWeek,
                        starthour: +(firstSectionState.timeOfDay?.slice(0, 2) || 0),
                        endhour: +(firstSectionState.timeOfDay?.slice(3, 5) || 0),
                    },
                })
                .then((res) => {
                    setUsersSectionState(res.data.getPrepopulatedSimData);
                    setIsFetchingUsers(false);
                });
        }
    }, [firstSectionState]);

    // ELEVATOR SECTION (Schema needs to be defined here to know which station is the equipment referring to)
    const [elevatorSectionState, setElevatorSectionState] = React.useState<ElevatorsSectionState>();

    React.useEffect(() => {
        if (!schema) return;
        setElevatorSectionState(
            Object.fromEntries(
                Object.keys(schema.properties.equipment.properties.elevators.properties).map(
                    (name) => [name, true]
                )
            )
        );
    }, [schema]);

    // ESCALATOR SECTION (Schema needs to be defined here to know which station is the equipment referring to)
    const [escalatorSectionState, setEscalatorSectionState] =
        React.useState<EscalatorsSectionState>();
    React.useEffect(() => {
        if (!schema) return;
        setEscalatorSectionState(
            Object.fromEntries(
                Object.keys(schema.properties.equipment.properties.escalators.properties).map(
                    (escalator, idx) => [escalator, idx % 2 || idx === 0 ? 'up' : 'down']
                )
            )
        );
    }, [schema]);

    // TRAIN SECTION
    const [trainSectionState, setTrainSectionState] = React.useState<TrainSectionState>();
    React.useEffect(() => {
        if (!schema) return;
        setTrainSectionState(
            Object.fromEntries(
                Object.keys(schema.properties.schedules.properties).map((k) => [
                    k,
                    {
                        start: +timeOfDay?.slice(0, 2) * 3600,
                        time_interval: 180,
                    },
                ])
            )
        );
    }, [schema]);

    // LAST SECTION
    const [simulationName, setSimulationName] = React.useState<string>('');
    const [isLoading, setIsLoading] = React.useState<boolean>(false);

    // SCHEMA CHECKS
    const [isParametersOk, setIsParametersOk] = React.useState<boolean>(true);
    React.useEffect(() => {
        if (!schema) return;
        const usersValidate = ajv.compile(schema.properties.users);
        const elevatorsValidate = ajv.compile(schema.properties.equipment.properties.elevators);
        const escalatorsValidate = ajv.compile(schema.properties.equipment.properties.escalators);
        const timeIntervals = Object.values(trainSectionState || {}).reduce(
            (b, train) => train?.time_interval >= 30 && b,
            true
        );

        setIsParametersOk(
            usersValidate(usersSectionState) &&
                elevatorsValidate(elevatorSectionState) &&
                escalatorsValidate(escalatorSectionState) &&
                timeIntervals
        );
    }, [usersSectionState, elevatorSectionState, escalatorSectionState, schema, trainSectionState]);

    // FIRE NEW SIMULATION
    const token = useAppSelector(selectAccessToken);
    const createSimulation = () => {
        if (!firstSectionState) return;

        setIsLoading(true);

        fetch(process.env.REACT_APP_SIMULATION_API_URL || '', {
            headers: {
                'Authorization': 'Bearer ' + token,
            },
            method: 'POST',
            body: JSON.stringify(
                getAPIInput(
                    stationName,
                    firstSectionState,
                    usersSectionState!,
                    elevatorSectionState!,
                    escalatorSectionState!,
                    trainSectionState!,
                    simulationName,
                    schema
                )
            ),
        })
            .then((response) => {
                if (!response.ok) {
                    throw new Error();
                } else {
                    setLocation('/simulations/rautatientori');
                    setIsLoading(false);
                }
            })
            .catch((e) => {
                console.log(e.message);
                setIsLoading(false);
                alert('Something went wrong with your request');
            });
    };

    return (
        <div id="new-simulation-view">
            <main>
                <div className="page-title">{stationName}</div>
                <FirstSection
                    getParameters={(state: FirstSectionState) => setFirstSectionState(state)}
                />
                {firstSectionState && (
                    <>
                        <Divider />
                        <Collapsible
                            trigger={<NewSimulationTrigger open={false} label="Users" />}
                            triggerWhenOpen={<NewSimulationTrigger open={true} label="Users" />}>
                            <UsersSection
                                state={usersSectionState}
                                setState={setUsersSectionState}
                                schema={schema?.properties.users}
                                isLoading={isFetchingUsers}
                            />
                        </Collapsible>
                        <Collapsible
                            trigger={<NewSimulationTrigger open={false} label="Equipments" />}
                            triggerWhenOpen={
                                <NewSimulationTrigger open={true} label="Equipments" />
                            }>
                            {!!schema && (
                                <div className="equipments-container">
                                    <ElevatorSection
                                        state={elevatorSectionState!}
                                        setState={setElevatorSectionState}
                                        stationName={stationName}
                                    />
                                    <EscalatorSection
                                        state={escalatorSectionState!}
                                        setState={setEscalatorSectionState}
                                        stationName={stationName}
                                    />
                                </div>
                            )}
                        </Collapsible>
                        {!!schema.properties.schedules && (
                            <Collapsible
                                trigger={
                                    <NewSimulationTrigger open={false} label="Time intervals" />
                                }
                                triggerWhenOpen={
                                    <NewSimulationTrigger open={true} label="Time intervals" />
                                }>
                                <TrainSection
                                    timeOfDay={
                                        firstSectionState.timeOfDay as typeof timeOfDay[number]
                                    }
                                    state={trainSectionState!}
                                    setState={setTrainSectionState}
                                />
                            </Collapsible>
                        )}
                        <Divider />
                        <LastSection
                            firstSectionState={firstSectionState}
                            isParametersOk={isParametersOk}
                            simulationName={simulationName}
                            setSimulationName={setSimulationName}
                            isLoading={isLoading}
                            createSimulation={createSimulation}
                        />
                    </>
                )}
            </main>
        </div>
    );
};

export default NewSimulationView;

export const NewSimulationTrigger: React.FC<{ open: boolean; label: string }> = ({
    open,
    label,
}) => {
    return (
        <div className="trigger">
            <div className="trigger-label">{label}</div>
            <div style={{ transform: `rotate(${open ? 180 : 0}deg)` }}>
                <ChevronDown />
            </div>
        </div>
    );
};
