import React from 'react';
import { useAppDispatch } from 'app/hooks';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import Parameters from './Parameters';
import { InputDataType, MetaDatatype } from '../SimulationMainView';

export interface ParametersProps {
    inputData?: InputDataType;
    metaData?: MetaDatatype;
}

const ParametersView: React.FC<ParametersProps> = (props) => {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setMainSidebarFull(false));
    }, []);

    return <Parameters {...props} />;
};

export default ParametersView;
