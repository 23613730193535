import { LivePeopleData } from './livePeopleDataManager';
import { MainLayer } from '_types';
import { Subscription } from '@apollo/client/node_modules/zen-observable-ts/module';
import { Visualization } from 'components/Visualizer/visualization';
import { LiveDensityData } from './liveDensityData';

export class LayersDataManager {
    viz: Visualization;
    pData: LivePeopleData;
    dData: LiveDensityData;
    currentSubscription: Subscription;
    currentMainLayer: LivePeopleData | LiveDensityData;
    mainLayerName: MainLayer;

    constructor(viz: Visualization) {
        this.viz = viz;

        this.pData = new LivePeopleData();
        this.dData = new LiveDensityData();
    }

    public SetMainLayer(layerName: MainLayer, pollInterval: number) {
        if (this.mainLayerName === layerName) return;

        this.mainLayerName = layerName;
        this.StopAssetSubscription();

        switch (layerName) {
            case 'people':
                this.currentMainLayer = this.pData;
                break;
            case 'density':
                this.currentMainLayer = this.dData;
        }

        this.currentMainLayer.SetMainViewSubscription(this.viz, pollInterval);
    }

    public StopAssetSubscription() {
        if (!this.currentMainLayer) return;

        this.currentMainLayer.StopMainViewSubscription();
    }
}
