import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import AuthReducer from 'features/auth/AuthSlice';
import StationViewReducer from 'features/StationView/StationViewSlice';
import PlaybackViewReducer from 'features/StationView/PlaybackViewSlice';
import SimulationViewReducer from 'features/StationView/SimulationSlice';
import { SimulationResponse } from '_types';

export const simulationAPI = createApi({
    reducerPath: 'simulationAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_SIMULATION_API_URL}`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.accessToken;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getSimulationAPI: builder.query({
            query: (path: string) => path,
        }),
    }),
});
export const { useGetSimulationAPIQuery } = simulationAPI;

export const digiTwinUIAPI = createApi({
    reducerPath: 'testAPI',
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_DIGI_TWIN_UI_API}`,
        prepareHeaders: (headers, { getState }) => {
            const token = (getState() as RootState).auth.accessToken;

            if (token) {
                headers.set('Authorization', `Bearer ${token}`);
            }

            return headers;
        },
    }),
    endpoints: (builder) => ({
        getDigiTwinUIAPI: builder.query({
            query: (path) => path,
        }),
    }),
});
export const { useGetDigiTwinUIAPIQuery } = digiTwinUIAPI;

export const store = configureStore({
    reducer: {
        auth: AuthReducer,
        stationView: StationViewReducer,
        playbackView: PlaybackViewReducer,
        simulationSlice: SimulationViewReducer,
        [simulationAPI.reducerPath]: simulationAPI.reducer,
        [digiTwinUIAPI.reducerPath]: digiTwinUIAPI.reducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false,
        }).concat(simulationAPI.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
    ReturnType,
    RootState,
    unknown,
    Action<string>
>;
