import React from "react";
import ReactEcharts from "echarts-for-react"; 
import { GETPEOPLECOUTDATAMONTHLY } from "_queries";
import { useQuery } from "@apollo/client";

function BarChartBike(barchart_data) {  

  if (!barchart_data) {
    return null; // Return null if the data is not available
  }

  const eqNameMap = new Map(barchart_data.map(item => [item.eq_name, item.count]));
  const expectedEqNames = ["AA01", "AA02", "AA03", "AA04", "AB05", "AB06", "AB07", "AB08", "AB09", "AB10", "AE01","AE02","AE03","AF04","Stairs"];
  // Ensure that the data has entries for all expected "eq_name" values
  const eqData = expectedEqNames.map(eq_name => ({
    eq_name,
    count: eqNameMap.has(eq_name) ? eqNameMap.get(eq_name) : null,
  }));

  // Extract the "eq_name" and count values
  const eq_name = eqData.map(item => item.eq_name);
  const eq_value = eqData.map(item => item.count);



  const option = {color: ['#7296F9'],
    xAxis: {
      type: 'category',
      data: eq_name,
      showGrid: false,axisLabel: {
        rotate: 45, // Rotate the x-axis labels by 45 degrees (adjust as needed)
        textStyle: {
          color: '#fff', // Label text color
          fontSize: 10,
        },
      },

    },
    yAxis: {
      type: 'value',showGrid: false,axisLabel: {textStyle: {color: '#fff',fontSize: 10},} ,
    },
    series: [
      {
        data: eq_value,
        type: 'bar',
          itemStyle: {
          borderRadius: 0.1,
          borderColor: '#fff',
          borderWidth: 0.1
          },
          
      }
    ]
  };
  return <ReactEcharts option={option} />;

}
  export default BarChartBike;