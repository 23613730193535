import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

export type SimulationMainViewOptions = 'results' | 'parameters' | 'playback';

export interface SimulationSlicestate {
    reached: number;
    peopleOnPlatform: number;
    mainView: SimulationMainViewOptions;
}

const initialState: SimulationSlicestate = {
    reached: 0,
    peopleOnPlatform: 0,
    mainView: 'results',
};

/*===============================
              Slice
===============================*/

export const SimulationViewSlice = createSlice({
    name: 'simulationView',
    initialState,
    reducers: {
        setSimulationReached: (state, action: PayloadAction<number>) => {
            state.reached = action.payload;
        },
        setSimulationPeopleOnPlatform: (state, action: PayloadAction<number>) => {
            state.peopleOnPlatform = action.payload;
        },
        setSimulationMainView: (state, action: PayloadAction<SimulationMainViewOptions>) => {
            state.mainView = action.payload;
            localStorage.setItem('simulationMainView', action.payload);
        },
    },
});

/*===============================
            Actions
===============================*/

export const { setSimulationReached, setSimulationPeopleOnPlatform, setSimulationMainView } =
    SimulationViewSlice.actions;

/*===============================
           Selectors
===============================*/

export const selectSimulationReached = (state: RootState) => state.simulationSlice.reached;
export const selectSimulationPeopleOnPlatform = (state: RootState) =>
    state.simulationSlice.peopleOnPlatform;
export const selectSimulationMainView = (state: RootState) => state.simulationSlice.mainView;

export default SimulationViewSlice.reducer;
