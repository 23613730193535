import { gql } from '@apollo/client';

export const GETPEOPLECOUTDATABYMONTH = gql`
    query MyQuery {
        MontlyDataPeople {
            count
            event_date
        }
    }
`;

export const GETPEOPLECOUTDATABYWEEK = gql`
    query MyQuery {
        weeklyDatapeople {
            bike {
                this_week_data {
                    Monday
                    Tuesday
                    Wednesday
                    Thursday
                    Friday
                    Saturday
                    Sunday
                }
                weekly_avg_data {
                    Monday
                    Tuesday
                    Wednesday
                    Thursday
                    Friday
                    Saturday
                    Sunday
                }
            }
            human {
                this_week_data {
                    Monday
                    Tuesday
                    Wednesday
                    Thursday
                    Friday
                    Saturday
                    Sunday
                }
                weekly_avg_data {
                    Monday
                    Tuesday
                    Wednesday
                    Thursday
                    Friday
                    Saturday
                    Sunday
                }
            }
        }
    }
`;

export const GETPEOPLECOUTDATADAILY = gql`
    query MyQuery {
        getPeopleCountDataRDS {
            count
            eq_name
            eq_type
            event_hour
            event_time
            id
            line_name
            reason
            sensor_id
            traffic_types
            utc_event_time
        }
    }
`;

export const GETPEOPLECOUTDATADAILYBYHOUR = gql`
    query MyQuery {
        getPeopleCountDataRDS {
            avg_last_3_days
            today_data
        }
    }
`;

export const GETMETROZONESDATA = gql`
    query MyQuery {
        getMetroZoneData {
            Emetro {
                Emetro_zone_A1
                Emetro_zone_A2
                Emetro_zone_A3
                Emetro_zone_B1
                Emetro_zone_B2
                Emetro_zone_B3
                Emetro_zone_C1
                Emetro_zone_C2
                Emetro_zone_C3
                Emetro_zone_D1
                Emetro_zone_D2
                Emetro_zone_D3
            }
            Wmetro {
                Wmetro_zone_A1
                Wmetro_zone_A2
                Wmetro_zone_A3
                Wmetro_zone_B1
                Wmetro_zone_B2
                Wmetro_zone_B3
                Wmetro_zone_C1
                Wmetro_zone_C2
                Wmetro_zone_C3
                Wmetro_zone_D1
                Wmetro_zone_D2
                Wmetro_zone_D3
            }
        }
    }
`;
export const GETPEOPLECOUTDATAWEEKLY = gql`
    query MyQuery {
        getPeopleCountDataRDSWeekly {
            count
            eq_name
            eq_type
            event_hour
            event_time
            id
            weekday
            line_name
            reason
            sensor_id
            traffic_types
            utc_event_time
        }
    }
`;

export const GETPEOPLECOUTDATAMONTHLY = gql`
    query MyQuery {
        getPeopleCountDataRDSMonthly {
            count
            eq_name
            eq_type
            event_hour
            event_time
            id
            weekday
            line_name
            reason
            sensor_id
            traffic_types
            event_date
        }
    }
`;

export const GETENERGYCONSUMPTIONMONTHLY = gql`
    query MyQuery {
        listEnergyConsumedMonthy {
            date_hour
            equipment_id
            equipment_type
            total_energy_consumed
            unit
        }
    }
`;

export const GETJOURNEYTIMEMONTHLY = gql`
    query MyQuery {
        getJourneyTimeMonthly {
            floor_name
            hour_time
            total_avg_journey_time
            traffic_type
        }
    }
`;
