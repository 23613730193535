import { SimulationResponse } from '_types';
import { useAppSelector } from 'app/hooks';
import { Button } from 'components/UIComponents/Button/Button';
import { selectAccessToken } from 'features/auth/AuthSlice';
import React from 'react';
import { FirstSectionState } from './FirstSection';
import { useRoute } from 'wouter';

type Props = {
    simulationName: string;
    setSimulationName: React.Dispatch<React.SetStateAction<string>>;
    firstSectionState: FirstSectionState;
    isParametersOk: boolean;
    isLoading: boolean;
    createSimulation: () => void;
};

const LastSection: React.FC<Props> = ({
    simulationName,
    setSimulationName,
    firstSectionState,
    isParametersOk,
    isLoading,
    createSimulation,
}) => {
    React.useEffect(
        () =>
            setSimulationName(
                (firstSectionState.dayOfWeek as string).toUpperCase() +
                    '_' +
                    (firstSectionState.timeOfDay as string)
            ),
        [firstSectionState]
    );

    const [_, params] = useRoute('/simulations/:stationName/new_simulation');
    const stationName = params?.stationName ?? '';

    const [namesList, setNamesList] = React.useState<string[]>([]);

    const token = useAppSelector(selectAccessToken);
    const fetchList = React.useCallback(
        async () =>
            fetch(`${process.env.REACT_APP_SIMULATION_API_URL}/${stationName}` || '', {
                headers: {
                    'Authorization': 'Bearer ' + token,
                },
            })
                .then((response) => response.json())
                .then((data: SimulationResponse[]) => {
                    setNamesList(data.map((d) => JSON.parse(d.Metadata || '')?.name || ''));
                }),
        []
    );

    React.useEffect(() => {
        fetchList().catch((e) => console.error(e));
    }, []);

    const enhancedCreateSimulation = () => {
        if (namesList.includes(simulationName)) {
            alert('This simulation name is already in use!');
            return;
        }

        createSimulation && createSimulation();
    };

    return (
        <div className="last-line">
            <div>Select a name</div>
            <input
                type="text"
                placeholder="select a name"
                value={simulationName}
                onChange={(e) => setSimulationName(e.target.value)}
            />
            <Button
                onClick={() => enhancedCreateSimulation()}
                disabled={!isParametersOk}
                isLoading={isLoading}>
                Create simulation
            </Button>
        </div>
    );
};

export default LastSection;
