import { Viewport } from './viewport';
import { Visualization } from './visualization';
import * as THREE from 'three';
import { DensityAssets } from './layers/densityAssets';
import { PeopleAssets } from './layers/peopleAssets';

export class Layers {
    vpt: Viewport;
    viz: Visualization;
    people: PeopleAssets;
    density: DensityAssets;

    constructor(vpt: Viewport) {
        this.vpt = vpt;
        this.viz = vpt.dependencies.get('visualization') as Visualization;

        this.people = new PeopleAssets(vpt);
        this.density = new DensityAssets(vpt);
    }
}
