import * as THREE from 'three';
import { ObjPosition, StationParams, Views, TypeOfFeatures } from '_types';

const params: StationParams = {};

const cameraViews: Views = new Map();

cameraViews.set('full_station', {
    label: 'Station overview',
    camera: {
        eye: new THREE.Vector3(126.5, 49, -111.7),
        target: new THREE.Vector3(150, -7, -158),
    },
    default: true,
});

cameraViews.set('asematunneli', {
    label: 'Asematunneli',
    camera: {
        eye: new THREE.Vector3(150.8, 56.3, -121.3),
        target: new THREE.Vector3(158.6, -5.2, -159),
    },
    visibleAssets: [
        { name: 'sea_level' },
        { name: 'escalator_AB05' },
        { name: 'escalator_AB06' },
        { name: 'escalator_AB07' },
        { name: 'escalator_AB08' },
        { name: 'escalator_AB09' },
        { name: 'escalator_AB10' },
        { name: 'escalator_AB11' },
        { name: 'escalator_AB12' },
        { name: 'escalator_AB13' },
        { name: 'escalator_AB14' },
        { name: 'escalator_AB15' },
        { name: 'escalator_AB16' },
        { name: 'escalator_ABXX' },
        { name: 'escalator_ABYY' },
        { name: 'escalator_ABZ1' },
        { name: 'escalator_ABZ2' },
        { name: 'AE03' },
        { name: 'AE03_shaft' },
        { name: 'AF04' },
        { name: 'AF04_shaft' },
    ],
    trainsAreNotVisible: true,
    axisYLimits: { bottom: -3.5, top: 4 },
});

cameraViews.set('kompassitaso', {
    label: 'Kompassitaso',
    camera: {
        eye: new THREE.Vector3(179.3, 31.8, -138),
        target: new THREE.Vector3(186, 1.1, -150),
    },
    visibleAssets: [
        { name: 'kompassi_level' },
        { name: 'escalator_AA01' },
        { name: 'escalator_AA02' },
        { name: 'escalator_AA03' },
        { name: 'escalator_AA04' },
        { name: 'escalator_AB05' },
        { name: 'escalator_AB06' },
        { name: 'escalator_AB07' },
        { name: 'escalator_AB08' },
        { name: 'escalator_AB09' },
        { name: 'escalator_AB10' },
        { name: 'AE01' },
        { name: 'AE01_shaft' },
        { name: 'AE02' },
        { name: 'AE02_shaft' },
        { name: 'AE03' },
        { name: 'AE03_shaft' },
        { name: 'AF04' },
        { name: 'AF04_shaft' },
    ],
    trainsAreNotVisible: true,
    axisYLimits: { bottom: -20, top: -1 },
});

cameraViews.set('metrolaituri', {
    label: 'Metrolaituri',
    camera: {
        eye: new THREE.Vector3(87.7, 10.6, -123),
        target: new THREE.Vector3(121.6, -17.4, -158),
    },
    visibleAssets: [
        { name: 'platform_level' },
        { name: 'escalator_AA01', opacity: 0.05 },
        { name: 'escalator_AA02', opacity: 0.05 },
        { name: 'escalator_AA03', opacity: 0.05 },
        { name: 'escalator_AA04', opacity: 0.05 },
        { name: 'AE01' },
        { name: 'AE02' },
        { name: 'AE01_shaft', opacity: 0.05 },
        { name: 'AE02_shaft', opacity: 0.05 },
    ],
    axisYLimits: { bottom: -Infinity, top: -4.5 },
});

cameraViews.set('elevator_1_2"', {
    label: 'AE01-2',
    camera: {
        eye: new THREE.Vector3(167, -3.1, -139.1),
        target: new THREE.Vector3(181.3, -8.4, -147),
    },
    visibleAssets: [
        { name: 'kompassi_level', opacity: 0.05 },
        { name: 'platform_level', opacity: 0.03 },
        { name: 'escalator_AA01', opacity: 0.3 },
        { name: 'escalator_AA02', opacity: 0.3 },
        { name: 'escalator_AA03', opacity: 0.3 },
        { name: 'escalator_AA04', opacity: 0.3 },
        { name: 'AE01', opacity: 0.7 },
        { name: 'AE01_shaft', opacity: 0.2 },
        { name: 'AE02', opacity: 0.7 },
        { name: 'AE02_shaft', opacity: 0.2 },
    ],
    axisYLimits: { bottom: -Infinity, top: -1 },
    equipment: 'elevator',
    trainsAreNotVisible: true,
});

cameraViews.set('elevator_3', {
    label: 'AE03',
    camera: {
        eye: new THREE.Vector3(195.4, 22, -162.4),
        target: new THREE.Vector3(182, -5.7, -175.9),
    },
    visibleAssets: [
        { name: 'kompassi_level' },
        { name: 'sea_level' },
        { name: 'escalator_AB11' },
        { name: 'escalator_AB12' },
        { name: 'AE03', opacity: 0.7 },
        { name: 'AE03_shaft', opacity: 0.2 },
    ],
    axisYLimits: { bottom: -20, top: 4 },
    equipment: 'elevator',
    trainsAreNotVisible: true,
});

cameraViews.set('elevator_4', {
    label: 'AF04',
    camera: {
        eye: new THREE.Vector3(200.7, 20, -162),
        target: new THREE.Vector3(186.6, 0, -150),
    },
    visibleAssets: [
        { name: 'kompassi_level' },
        { name: 'sea_level' },
        { name: 'AF04', opacity: 0.7 },
        { name: 'AF04_shaft', opacity: 0.2 },
    ],
    axisYLimits: { bottom: -20, top: 4 },
    equipment: 'elevator',
    trainsAreNotVisible: true,
});

cameraViews.set('escalator_06', {
    label: 'AB06',
    camera: {
        eye: new THREE.Vector3(203.62, 8.9, -168.15),
        target: new THREE.Vector3(196, -2.72, -173.3),
    },
    visibleAssets: [
        { name: 'kompassi_level', opacity: 0.05 },
        { name: 'escalator_AA01', opacity: 0.05 },
        { name: 'escalator_AA02', opacity: 0.05 },
        { name: 'escalator_AA03', opacity: 0.05 },
        { name: 'escalator_AA04', opacity: 0.05 },
        { name: 'escalator_AB05', opacity: 0.05 },
        { name: 'escalator_AB06', opacity: 0.7 },
        { name: 'escalator_AB07', opacity: 0.05 },
        { name: 'escalator_AB08', opacity: 0.05 },
        { name: 'escalator_AB09', opacity: 0.05 },
        { name: 'escalator_AB10', opacity: 0.05 },
        { name: 'AE01', opacity: 0.05 },
        { name: 'AE01_shaft', opacity: 0.05 },
        { name: 'AE02', opacity: 0.05 },
        { name: 'AE02_shaft', opacity: 0.05 },
        { name: 'AE03', opacity: 0.05 },
        { name: 'AE03_shaft', opacity: 0.05 },
        { name: 'AF04', opacity: 0.05 },
        { name: 'AF04_shaft', opacity: 0.05 },
    ],
    trainsAreNotVisible: true,
    axisYLimits: { bottom: -20, top: -1 },
    equipment: 'escalator',
});

cameraViews.set('escalator_07', {
    label: 'AB07',
    camera: {
        eye: new THREE.Vector3(204.56, 7.86, -159.4),
        target: new THREE.Vector3(201.23, 2.44, -155.95),
    },
    visibleAssets: [
        { name: 'kompassi_level', opacity: 0.05 },
        { name: 'escalator_AA01', opacity: 0.05 },
        { name: 'escalator_AA02', opacity: 0.05 },
        { name: 'escalator_AA03', opacity: 0.05 },
        { name: 'escalator_AA04', opacity: 0.05 },
        { name: 'escalator_AB05', opacity: 0.05 },
        { name: 'escalator_AB06', opacity: 0.05 },
        { name: 'escalator_AB07', opacity: 0.7 },
        { name: 'escalator_AB08', opacity: 0.05 },
        { name: 'escalator_AB09', opacity: 0.05 },
        { name: 'escalator_AB10', opacity: 0.05 },
        { name: 'AE01', opacity: 0.05 },
        { name: 'AE01_shaft', opacity: 0.05 },
        { name: 'AE02', opacity: 0.05 },
        { name: 'AE02_shaft', opacity: 0.05 },
        { name: 'AE03', opacity: 0.05 },
        { name: 'AE03_shaft', opacity: 0.05 },
        { name: 'AF04', opacity: 0.05 },
        { name: 'AF04_shaft', opacity: 0.05 },
    ],
    trainsAreNotVisible: true,
    axisYLimits: { bottom: -20, top: -1 },
    equipment: 'escalator',
});

cameraViews.forEach((view) => {
    if (view.axisYLimits) {
        view.axisYLimits.bottom =
            view.axisYLimits.bottom * (params.scale?.y ?? 1) + (params.position?.y ?? 0);
        view.axisYLimits.top =
            view.axisYLimits.top * (params.scale?.y ?? 1) + (params.position?.y ?? 0);
    }
});

const analysisViews: Views = new Map();

analysisViews.set('analysis_asematunneli', {
    label: 'Asematunneli',
    camera: {
        eye: new THREE.Vector3(197.815, 68.872, -147.6400119827077),
        target: new THREE.Vector3(196.70005758863894, -4.611646617542902, -168.42540194568264),
    },
    visibleAssets: [
        { name: 'sea_level' },
        { name: 'escalator_AB05' },
        { name: 'escalator_AB06' },
        { name: 'escalator_AB07' },
        { name: 'escalator_AB08' },
        { name: 'escalator_AB09' },
        { name: 'escalator_AB10' },
        { name: 'escalator_AB11' },
        { name: 'escalator_AB12' },
        { name: 'escalator_AB13' },
        { name: 'escalator_AB14' },
        { name: 'escalator_AB15' },
        { name: 'escalator_AB16' },
        { name: 'escalator_ABXX' },
        { name: 'escalator_ABYY' },
        { name: 'escalator_ABZ1' },
        { name: 'escalator_ABZ2' },
        { name: 'AE03' },
        { name: 'AE03_shaft' },
        { name: 'AF04' },
        { name: 'AF04_shaft' },
    ],
    trainsAreNotVisible: true,
    axisYLimits: { bottom: -3.5, top: 4 },
});

analysisViews.set('analysis_kompassitaso', {
    label: 'Kompassitaso',
    camera: {
        eye: new THREE.Vector3(206, 33.7, -149.5),
        target: new THREE.Vector3(205.48, -2.148, -159.6),
    },
    default: true,
    visibleAssets: [
        { name: 'kompassi_level' },
        { name: 'escalator_AB05' },
        { name: 'escalator_AB06' },
        { name: 'escalator_AB07' },
        { name: 'escalator_AB08' },
        { name: 'escalator_AB09' },
        { name: 'escalator_AB10' },
    ],
    trainsAreNotVisible: true,
    axisYLimits: { bottom: -20, top: -1 },
});

analysisViews.set('analysis_metrolaituri', {
    label: 'Metrolaituri',
    camera: {
        eye: new THREE.Vector3(160.64041315749955, 37.23284198801081, -149.91178670548186),
        target: new THREE.Vector3(160.08098655375545, -17.81420077959973, -161.21311956563028),
    },
    visibleAssets: [
        { name: 'platform_level' },
        { name: 'escalator_AA01', opacity: 0.05 },
        { name: 'escalator_AA02', opacity: 0.05 },
        { name: 'escalator_AA03', opacity: 0.05 },
        { name: 'escalator_AA04', opacity: 0.05 },
        { name: 'AE01', opacity: 0.05 },
        { name: 'AE02', opacity: 0.05 },
        { name: 'AE01_shaft', opacity: 0.05 },
        { name: 'AE02_shaft', opacity: 0.05 },
    ],
    axisYLimits: { bottom: -Infinity, top: -4.5 },
});

analysisViews.forEach((view) => {
    if (view.axisYLimits) {
        view.axisYLimits.bottom =
            view.axisYLimits.bottom * (params.scale?.y ?? 1) + (params.position?.y ?? 0);
        view.axisYLimits.top =
            view.axisYLimits.top * (params.scale?.y ?? 1) + (params.position?.y ?? 0);
    }
});

const urls = [
    'RT_-1.gltf',
    'RT_-2.gltf',
    'RT_-3.gltf',
    'RT_-3_-2_staircase.gltf',
    'RT_0.gltf',
    'RT_AA01.gltf',
    'RT_AA02.gltf',
    'RT_AA03.gltf',
    'RT_AA04.gltf',
    'RT_AB05.gltf',
    'RT_AB06.gltf',
    'RT_AB07.gltf',
    'RT_AB08.gltf',
    'RT_AB09.gltf',
    'RT_AB10.gltf',
    'RT_AB11.gltf',
    'RT_AB12.gltf',
    'RT_AB13.gltf',
    'RT_AB14.gltf',
    'RT_AB15.gltf',
    'RT_AB16.gltf',
    'RT_ABXX.gltf',
    'RT_ABYY.gltf',
    'RT_ABZ1.gltf',
    'RT_ABZ2.gltf',
    'RT_AE01_shaft.gltf',
    'RT_AE01_LD_-2.gltf',
    'RT_AE01_LD_-3.gltf',
    'RT_AE02_shaft.gltf',
    'RT_AE02_LD_-2.gltf',
    'RT_AE02_LD_-3.gltf',
    'RT_AE03_LD_-1.gltf',
    'RT_AE03_LD_-2.gltf',
    'RT_AE03_LD_0.gltf',
    'RT_AE03_shaft.gltf',
    'RT_AF04_shaft.gltf',
    'RT_AF04_LD_-1.gltf',
    'RT_AF04_LD_-2.gltf',
    'RT_AF04_LD_0.gltf',
    'RT_train_to_east.gltf',
    'RT_train_to_west.gltf',
    'RT_AE01.gltf',
    'RT_AE02.gltf',
    'RT_AE03.gltf',
    'RT_AF04.gltf',
].map((s) => '/content/rautatientori/' + s);

const rautatientoriFeatures: TypeOfFeatures = {
    views: cameraViews,
    urls: urls,
    analysisViews,
    stationParams: params,
    assets: {
        elevators: [
            { name: 'AE01', shaft: 'AE01_shaft', id: 11292263 },
            { name: 'AE02', shaft: 'AE02_shaft', id: 11292264 },
            { name: 'AE03', shaft: 'AE03_shaft', id: 11144120 },
            { name: 'AF04', shaft: 'AF04_shaft', id: 10095336 },
        ],
        escalators: [
            { name: 'escalator_AA01', id: 10108519 },
            { name: 'escalator_AA02', id: 10108525 },
            { name: 'escalator_AA03', id: 10108528 },
            { name: 'escalator_AA04', id: 10108531 },
            { name: 'escalator_AB05', id: 12345678 },
            { name: 'escalator_AB06', id: 12345678 },
            { name: 'escalator_AB07', id: 12345678 },
            { name: 'escalator_AB08', id: 12345678 },
            { name: 'escalator_AB09', id: 43925789 },
            { name: 'escalator_AB10', id: 43925790 },
            { name: 'escalator_AB11', id: 43925791 },
            { name: 'escalator_AB12', id: 43925793 },
            { name: 'escalator_AB13', id: 43925795 },
            { name: 'escalator_AB14', id: 43925797 },
            { name: 'escalator_AB15', id: 11583673 },
            { name: 'escalator_AB16', id: 11583677 },
            { name: 'escalator_ABXX', id: 42657481 },
            { name: 'escalator_ABYY', id: 42657482 },
            { name: 'escalator_ABZ1', id: 43005215 },
            { name: 'escalator_ABZ2', id: 43005216 },
        ],
        trains: [{ name: 'metrotrain_to_east' }, { name: 'metro_train_to_west' }],
    },
    onTracks: [
        ({ x, y, z }: ObjPosition) => {
            if (y > -19) return false;
            if (
                z > -0.0466417910448 * x - 168.755597014925 &&
                z < -0.04868913858 * x - 136.496254681648
            )
                return false;
            return true;
        },
    ],
};

export default rautatientoriFeatures;
