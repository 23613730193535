import React from 'react';
import './button.scss';

type Props = React.HTMLProps<HTMLButtonElement> & {
    type?: 'button' | 'submit' | 'reset' | undefined;
    isLoading?: boolean;
};

export const Button: React.FC<Props> = (props) => {
    const { children, isLoading, ...rest } = props;
    return (
        <button {...rest} className={(rest.className ?? '') + (rest.disabled ? ' disabled' : '')}>
            {isLoading ? <div className="button-spinner" /> : children}
        </button>
    );
};

export const IconButton: React.FC<Props> = (props) => {
    const { children, isLoading, ...rest } = props;
    return (
        <button
            {...rest}
            className={'icon-button ' + (rest.className ?? '') + (rest.disabled && ' disabled')}>
            {isLoading ? <div className="button-spinner" /> : children}
        </button>
    );
};
