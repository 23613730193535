import React from 'react';
import { useAppDispatch } from 'app/hooks';
import { setMainSidebarFull } from 'features/StationView/StationViewSlice';
import PlaybackView from './PlaybackView';

const StationTwinPlayback: React.FC = () => {
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        dispatch(setMainSidebarFull(false));
    }, []);

    return <PlaybackView />;
};

export default StationTwinPlayback;
