import TimePicker from 'components/UIComponents/TimePicker/TimePicker';
import React from 'react';
import { timeOfDay } from './FirstSection';

import _ from 'lodash';

type TrainIntervals = {
    start: number;
    time_interval: number;
};
export type TrainSectionState = {
    [k: string]: TrainIntervals;
};

type Props = {
    timeOfDay: typeof timeOfDay[number];
    state: TrainSectionState;
    setState: React.Dispatch<React.SetStateAction<TrainSectionState | undefined>>;
};

const TrainSection: React.FC<Props> = ({ timeOfDay, state, setState }) => {
    React.useEffect(
        () =>
            setState((s) => {
                const copyState = _.cloneDeep(s);
                for (const metro_direction in s) {
                    copyState![metro_direction].start = +timeOfDay?.slice(0, 2) * 3600;
                }
                return copyState;
            }),
        [timeOfDay]
    );

    return (
        <div className="schedules-container">
            {Object.entries(state).map(([metroDirection, value]) => (
                <div key={metroDirection} className="schedules-side">
                    <span className="schedule-side-title">
                        {metroDirection.split('_')[0] + ' side'}
                    </span>
                    <div className="schedules-elements">
                        <div className="schedules">
                            <span className="schedules-title">First train (hh:mm:ss)</span>
                            <TimePicker
                                value={value.start}
                                onChange={(number) =>
                                    setState((s) => ({
                                        ...s,
                                        [metroDirection]: { ...s![metroDirection], start: number },
                                    }))
                                }
                            />
                        </div>
                        <div className="schedules">
                            <span className="schedules-title">Interval between trains (mm:ss)</span>
                            <TimePicker
                                value={value.time_interval}
                                onChange={(number) =>
                                    setState((s) => ({
                                        ...s,
                                        [metroDirection]: {
                                            ...s![metroDirection],
                                            time_interval: number,
                                        },
                                    }))
                                }
                                showHours={false}
                            />
                        </div>
                    </div>
                </div>
            ))}
        </div>
    );
};

export default TrainSection;
