import { Visualization } from 'components/Visualizer/visualization';
import { PeopleLiveDataToPeopleDraw } from '../../util';
import WsClients from 'app/wsClient';
import { LDM } from '../ldm';
import { PeopleDraw } from '_types';

export class LivePeopleData implements LDM {
    viz: Visualization;
    wsClient: WsClients;
    subscriptionStopped: boolean;
    blobURL: string;
    worker: Worker;
    events: PeopleDraw[][] = [];

    constructor() {
        this.wsClient = new WsClients();
        this.subscriptionStopped = true;

        const blob = new Blob([
            `var i = 0;

            function timedCount() {
            i = i + 1;
            postMessage(i);
            setTimeout("timedCount()", 2900);
            }

            timedCount();`,
        ]);
        this.blobURL = window.URL.createObjectURL(blob);
    }

    public SetMainViewSubscription(viz: Visualization) {
        this.viz = viz;
        this.subscriptionStopped = false;

        this.wsClient.clients.forEach(
            (client, idx) =>
                (client.onmessage = (message) => this.subscribeFunction.bind(this)(message, idx))
        );
    }

    private subscribeFunction(message: MessageEvent, idx: number) {
        const data = JSON.parse(message.data);

        this.events[idx] = data.map((person) => PeopleLiveDataToPeopleDraw(person));

        this.viz.layers.people.UpdateMeshes(this.events.flat());
    }

    public StopMainViewSubscription() {
        this.wsClient.clients.forEach((client) => (client.onmessage = null));
        this.viz.layers.people.HidePeople();
        this.subscriptionStopped = true;
    }

    public FocusedOut() {
        this.worker = new Worker(this.blobURL);
        this.worker.onmessage = this.RefreshConnection.bind(this);
    }

    public FocusedIn() {
        this.worker?.terminate();
    }

    public RefreshConnection() {
        if (!this.subscriptionStopped) {
            this.wsClient.Close();
            this.wsClient.Open();
            this.SetMainViewSubscription(this.viz);
        }
    }
}
/* export class LivePeopleData implements LDM {
    viz: Visualization;
    client: ApolloClient<NormalizedCacheObject>;
    query: ObservableQuery<any, OperationVariables>;
    peoplePosition: ObjPosition[];
    assetSubscription: Subscription;

    constructor() {
        this.client = apolloClient;
        this.SetQuery(500);
    }

    private SetQuery(pollInterval: number) {
        this.query = this.client.watchQuery({
            query: GETPEOPLE,
            pollInterval,
        });
    }

    public SetMainViewSubscription(viz: Visualization, pollInterval: number) {
        if (viz) this.viz = viz;

        this.SetQuery(pollInterval);
        const subscription = this.query.subscribe({
            next: (data) => this.subscribeFunction.bind(this)(data),
            error: (e) => console.error('---Apollo error---', e),
        });

        this.assetSubscription = subscription;
    }

    private subscribeFunction(data: Data) {
        const events: PeopleDraw[] = [];
        data.data.listLiveJourneyTime.forEach((o) =>
            JSON.parse(o.position).forEach((person) =>
                events.push(PeopleLiveDataToPeopleDraw(person))
            )
        );

        this.viz.layers.people.UpdateMeshes(events);
    }

    public StopMainViewSubscription() {
        if (!this.assetSubscription) return;

        this.viz.layers.people.HidePeople();
        this.assetSubscription.unsubscribe();
    }
}

interface Data {
    data: {
        listLiveJourneyTime: {
            position: string;
        }[];
    };
} */
