import React from 'react';
import ReactEcharts from 'echarts-for-react';
import './graph.scss';

interface Props extends React.HTMLProps<HTMLDivElement> {
    title: string;
    value?: number;
    colors?: [number, string][];
    lineWidth?: number;
    fontSize?: number | string;
    valueLabel?: string;
    min?: number;
    max?: number;
    tickDistance?: number;
}

const GaugeGraph: React.FC<Props> = (props) => {
    const {
        title,
        value = 0,
        colors = [
            [0.25, '#00ff00'],
            [0.5, 'yellow'],
            [0.75, 'orange'],
            [1, 'red'],
        ],
        lineWidth = 8,
        fontSize = 15,
        valueLabel = '',
        min = 0,
        max = 100,
        tickDistance = -40,
        ...rest
    } = props;

    const color = colors.find((el) => value < el[0] * max)?.[1] ?? colors[colors.length - 1][1];

    const option = {
        series: [
            {
                type: 'gauge',
                min,
                max,
                progress: {
                    show: true,
                    width: lineWidth,
                },
                splitNumber: 5,
                axisLine: {
                    lineStyle: {
                        width: lineWidth,
                        color: colors,
                        opacity: 0.2,
                    },
                },
                axisTick: {
                    show: false,
                },
                splitLine: {
                    show: false,
                },
                axisLabel: {
                    distance: tickDistance,
                    color: 'white',
                    fontSize,
                    opacity: 1,
                },
                title: {
                    show: false,
                },
                detail: {
                    valueAnimation: true,
                    fontSize,
                    color: color,
                    offsetCenter: [0, '70%'],
                    formatter: value + valueLabel,
                },
                data: [
                    {
                        value: value,
                        itemStyle: {
                            color: color,
                        },
                    },
                ],
            },
        ],
    };
    return (
        <div className="gauge-container" {...rest}>
            <span className="gauge-charts-title">{title}</span>
            <ReactEcharts option={option} className="gauge-charts" />
        </div>
    );
};
export default GaugeGraph;
