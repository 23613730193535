import React from 'react';
import { z } from 'zod';
import Select from 'components/UIComponents/Select/Select';
import { Button } from 'components/UIComponents/Button/Button';

const daysOfWeek = [
    'monday',
    'tuesday',
    'wednesday',
    'thursday',
    'friday',
    'saturday',
    'sunday',
] as const;
export const timeOfDay = [
    '06-07',
    '07-08',
    '08-09',
    '09-10',
    '10-11',
    '11-12',
    '12-13',
    '13-14',
    '14-15',
    '15-16',
    '16-17',
    '17-18',
    '18-19',
    '19-20',
    '20-21',
    '21-22',
    '22-23',
    '23-24',
] as const;
const dowOptions = daysOfWeek.map((v) => ({
    value: v,
    label: v[0].toUpperCase() + v.slice(1, 10000),
}));
const todOptions = timeOfDay.map((v) => ({
    value: v,
    label: v.slice(0, 2) + ':00 ' + v.slice(2, 5) + ':00',
}));

export type FirstSectionState = {
    dayOfWeek: typeof daysOfWeek[number] | undefined;
    timeOfDay: typeof timeOfDay[number] | undefined;
};

const firstSectionSchema = z.object({
    dayOfWeek: z.enum(daysOfWeek),
    timeOfDay: z.enum(timeOfDay),
});

const FirstSection: React.FC<{ getParameters: (state: FirstSectionState) => void }> = ({
    getParameters,
}) => {
    const [state, setState] = React.useState<FirstSectionState>({
        dayOfWeek: undefined,
        timeOfDay: undefined,
    });
    const [isStateOk, setIsStateOk] = React.useState(false);

    React.useEffect(() => {
        setIsStateOk(firstSectionSchema.safeParse(state).success);
    }, [state]);

    return (
        <div className="grid-3">
            <FirstSectionGridElement label="Select a day of the week">
                <Select
                    placeholder="Select a day of the week"
                    onChange={(v) =>
                        setState((s) => ({
                            ...s,
                            dayOfWeek: (v as { value: typeof daysOfWeek[number] }).value,
                        }))
                    }
                    options={dowOptions}
                />
            </FirstSectionGridElement>
            <FirstSectionGridElement label="Select a time slot">
                <Select
                    placeholder="Select a time slot"
                    onChange={(v) =>
                        setState((s) => ({
                            ...s,
                            timeOfDay: (v as { value: typeof timeOfDay[number] }).value,
                        }))
                    }
                    options={todOptions}
                />
            </FirstSectionGridElement>
            <FirstSectionGridElement label="">
                <div style={{ display: ' flex', alignContent: 'center', height: '100%' }}>
                    <Button
                        disabled={!isStateOk}
                        onClick={() => {
                            if (isStateOk) getParameters(state);
                        }}>
                        Get parameters
                    </Button>
                </div>
            </FirstSectionGridElement>
        </div>
    );
};

export default FirstSection;

interface GridElementProps extends React.HTMLProps<HTMLDivElement> {
    label?: string;
}

export const FirstSectionGridElement: React.FC<GridElementProps> = (props) => {
    const { label, ...rest } = props;
    return (
        <div className="grid-element">
            {label !== undefined && <label htmlFor="grid-element">{label}</label>}
            <div {...rest}></div>
        </div>
    );
};
